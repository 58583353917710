import React, { useState } from 'react';
import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { HStack, Spacer, AccordionButton, AccordionPanel, AccordionItem, Box, AccordionIcon, BoxProps, Stack } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { TransactionReceipt } from 'ethereum-abi-types-generator';

import { Range } from './Range';
import { FeeGains } from './FeeGains';
import { Liquidity } from './Liquidity';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { i_text_copy } from '../../../../style';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import CardTagContainer from '../../../../iZUMi-UI-toolkit/src/components/CardTag/Container';
import { CardTag } from '../../../../iZUMi-UI-toolkit/src/components/CardTag/CardTag';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import { useLiquidityManagerContract } from '../../../../hooks/useContracts';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import useIsMobile from '../../../../hooks/useIsMobile';
import { RootState } from '../../../../state/store';
import { useTranslation } from 'react-i18next';
import { LiquidityDetail } from '../../../../state/models/trade/liquidity/types';
import { getChain, getTxLink } from '../../../../config/chains';
import { ToastLink, useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { buildSendingParams } from '../../../../utils/contractHelpers';

const PositionListEntryContext = createContext<any | null>(null);
export const PositionListEntryProvider = PositionListEntryContext.Provider;

export type PositionListEntryProps = {
    entry: LiquidityDetail;
    handleRefreshLiquidity: () => void;
    handleAddLiquidity: (tokenId: string) => void;
    handleRemoveLiquidity: (tokenId: string) => void;
    handleCollectAll: (
        tokenId: string,
        tokenX: TokenInfoFormatted,
        tokenY: TokenInfoFormatted,
        gasPrice: number
    ) => Promise<TransactionReceipt>;
    handleApproveBox: () => Promise<TransactionReceipt>;
    isZip: boolean;
} & BoxProps;

export const PositionListEntry: React.FC<PositionListEntryProps> = (props) => {
    const { entry, handleRefreshLiquidity, handleAddLiquidity, handleRemoveLiquidity, handleCollectAll, handleApproveBox, isZip, ...rest } =
        props;
    const { t } = useTranslation();
    const toast = useCustomToast();
    const [toggle, setToggle] = useState(false);
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const liquidityManagerContract = useLiquidityManagerContract();
    const { chainId, account } = useWeb3WithDefault();
    const isMobile = useIsMobile();
    const chain = getChain(chainId);
    const toastLink = {} as ToastLink;
    const { gasPrice } = useGasPrice();

    const { iZiSwapLiquidityList } = useSelector((state: RootState) => state);
    const nftNotNeedBox = !entry?.tokenX.wrapTokenAddress && !entry?.tokenY.wrapTokenAddress;
    const nftNeedApproveForBox = !nftNotNeedBox && !iZiSwapLiquidityList.isApprovedForBox;
    const DetailsButton = (
        <>
            {!isMobile && <Spacer mt={{ base: '0px !important', sm: 'unset' }} />}
            <AccordionButton
                px={{ base: '5px', sm: '16px' }}
                color="secondary.500"
                w={{ base: 'unset', sm: '80px' }}
                className={i_text_copy}
                fontSize="12px !important"
                ml={{ base: '10px !important', sm: '20px !important' }}
            >
                {!oneLineMode && <Box>{t('Details')}</Box>}
                <AccordionIcon />
            </AccordionButton>
        </>
    );

    const inRange = Number(entry.currentPrice) <= Number(entry.maxPrice) && Number(entry.currentPrice) >= Number(entry.minPrice);

    return (
        <Card {...rest} position="relative" border={inRange ? '1px' : '0'} borderColor="primary.300">
            <CardTagContainer>{inRange && <CardTag variant="blue" text={t('In Range')} />}</CardTagContainer>

            <AccordionItem border="0">
                <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    py={{ base: '25px', xxl: '10px' }}
                    pl="40px"
                    pr="30px"
                    spacing="20px"
                    minW={{ base: '100%', sm: '660px' }}
                    justifyContent="end"
                    alignItems={{ base: 'start', sm: 'unset' }}
                >
                    {isZip ? null : (
                        <HStack spacing={{ base: '10px', sm: '28px' }} flexShrink={0}>
                            <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                            <FeeRate tokenA={entry.tokenX} tokenB={entry.tokenY} feeTier={entry.fee} initialToggle={toggle} />
                            {isMobile && DetailsButton}
                        </HStack>
                    )}

                    <HStack spacing={{ base: '4px', sm: '20px' }} ml={{ base: '0px !important', sm: '20px !important' }}>
                        <NFTId id={entry.tokenId} link="" />
                        <Range
                            max={entry.maxPriceDecimal}
                            min={entry.minPriceDecimal}
                            initialToggle={toggle}
                            onClick={() => {
                                setToggle(!toggle);
                            }}
                        />
                    </HStack>
                    {(oneLineMode || (isZip && oneLineMode)) && (
                        <>
                            <FeeGains
                                w={{ base: '100%', sm: '280px' }}
                                pr={{ base: '10px', sm: 'unset' }}
                                data={entry}
                                onCollect={() => {
                                    handleCollectAll(entry.tokenId, entry.tokenX, entry.tokenY, gasPrice).then((e) => {
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                        }
                                        toast('success', 'Collect successfully', undefined, toastLink);
                                        handleRefreshLiquidity();
                                    });
                                }}
                                nftNeedApproveForBox={nftNeedApproveForBox}
                                onApproveBox={() =>
                                    handleApproveBox().then((e: any) => {
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                        }
                                        toast('success', 'Approve successfully', undefined, toastLink);
                                        handleRefreshLiquidity();
                                    })
                                }
                            />
                            <Liquidity
                                w={{ base: '100%', sm: '280px' }}
                                empty={entry.tokenXLiquidity.eq(0) && entry.tokenYLiquidity.eq(0)}
                                liquidity={entry.liquidityValue}
                                onAdd={() => {
                                    handleAddLiquidity(entry.tokenId);
                                }}
                                onRemove={() => {
                                    handleRemoveLiquidity(entry.tokenId);
                                }}
                                onBurn={() => {
                                    liquidityManagerContract?.methods
                                        .burn(entry.tokenId)
                                        .send(
                                            buildSendingParams(
                                                chainId,
                                                {
                                                    from: account as string,
                                                    maxFeePerGas: gasPrice,
                                                    value: '0',
                                                },
                                                gasPrice
                                            ) as any
                                        )
                                        .on('transactionHash', (hash: string) => {
                                            if (chain) {
                                                toastLink.title = 'View on ' + chain.name;
                                                toastLink.link = getTxLink(hash, chain);
                                            }
                                            toast('info', 'Ongoing', undefined, toastLink);
                                        })
                                        .then((e: any) => {
                                            toast('info', 'Burn successfully', undefined, toastLink);
                                            console.log(e);
                                        });
                                }}
                            />
                        </>
                    )}
                    {!oneLineMode && !isMobile && DetailsButton}
                </Stack>
                {!oneLineMode && (
                    <AccordionPanel>
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: '10px', sm: '20px' }}
                            pl={{ base: '0px', sm: '50px' }}
                        >
                            <FeeGains
                                data={entry}
                                onCollect={() =>
                                    handleCollectAll(entry.tokenId, entry.tokenX, entry.tokenY, gasPrice).then((e) => {
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                        }
                                        toast('success', 'Collect successfully', undefined, toastLink);
                                        handleRefreshLiquidity();
                                    })
                                }
                                nftNeedApproveForBox={nftNeedApproveForBox}
                                onApproveBox={() =>
                                    handleApproveBox().then((e: any) => {
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                        }
                                        toast('success', 'Approve successfully', undefined, toastLink);
                                        handleRefreshLiquidity();
                                    })
                                }
                            />
                            <Liquidity
                                liquidity={entry.liquidityValue}
                                empty={entry.tokenXLiquidity.eq(0) && entry.tokenYLiquidity.eq(0)}
                                onAdd={() => {
                                    handleAddLiquidity(entry.tokenId);
                                }}
                                onRemove={() => {
                                    handleRemoveLiquidity(entry.tokenId);
                                }}
                                onBurn={() => {
                                    toast('info', 'Burn successfully', undefined, toastLink);
                                    console.log('burn.......');
                                }}
                            />
                        </Stack>
                    </AccordionPanel>
                )}
            </AccordionItem>
        </Card>
    );
};

export default PositionListEntry;
