import { Flex, Text, Image, HStack, useColorMode, useBreakpointValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_h2_title, i_text_copy, i_text_d, i_text_piece3, i_text_piece4_content } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import '../../Home.css';

const HomePiece4: React.FC = () => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [cardFlow, setCardFlow] = useState(false);

    const history = useHistory();

    const content =
        'Incorporated with the innovative Discretized-Liquidity-AMM model, iZiSwap is a next-generation DEX on Multi-Chains to maximize capital efficiency by realizing Limit Order in a decentralized way.';
    // const numberOfLineBreaks = (content.match(/\n/g) || []).length;
    // console.log('Number of breaks: ' + numberOfLineBreaks);
    const tabs = [
        {
            title: 'Precise',
            content: 'Trade tokens at an exact price to avoid front-run and slippage',
        },
        {
            title: 'Efficient',
            content: 'Enjoy more LP fees and high APR with maximized capital efficiency',
        },
        {
            title: 'Secure',
            content: 'Keep your money 100% in your wallet in a fully decentralized and non-custodial way',
        },
    ];
    return (
        <Flex className={cardFlow ? 'cardFlow' : 'cardLeave'} w="100%" justifyContent="center" mt={{ base: '16px', md: '16px', xl: '0px' }}>
            <Card
                w={{ base: '355px', md: '670px', xl: '967px' }}
                h={{ base: '742px', md: '640px', xl: '574px' }}
                onMouseOver={() => {
                    setCardFlow(true);
                }}
                onMouseLeave={() => {
                    setCardFlow(false);
                }}
            >
                <Flex
                    h={{ base: '62px', md: '98px' }}
                    pl={{ base: '20px', md: '41px' }}
                    pr="20px"
                    alignItems="center"
                    borderRadius="6px 6px 0px 0px"
                    bg={colorTheme('linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 83.85%, #FFFFFF 100%)', '#292343')}
                    boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                    zIndex="1"
                >
                    <Image boxSize="28px" src={process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon1.svg'}></Image>
                    <Text className={i_h2_title} ml="15px" color={colorTheme('tertiary.800', '#F5F5F5')}>
                        {t('iZiSwap')}
                    </Text>
                    {!isMobile && (
                        <CustomButton
                            className={i_text_copy}
                            w={{ base: '151px', xl: '205px' }}
                            h={{ base: '42px', xl: '51px' }}
                            variant="purple"
                            text="Swap Now"
                            ml="auto"
                            fontWeight="700"
                            onClick={() => {
                                history.push('/trade/swap');
                            }}
                        ></CustomButton>
                    )}
                </Flex>
                <Flex direction="column" flex="1" bgColor={colorTheme('#ffffff', '#211834')}>
                    <Flex mt="27px" px={{ base: '20px', xl: '41px' }} justifyContent="center">
                        <Text className={i_text_piece4_content} color="#6A5E86">
                            {t(content)}
                        </Text>
                    </Flex>
                    <Flex h="100%" alignItems="center" justifyContent="center">
                        <Image
                            w={{ base: '326px', xl: '654px' }}
                            h={{ base: '123px', xl: '247px' }}
                            src={process.env.PUBLIC_URL + '/assets/home/homePiece4/formula.svg'}
                        ></Image>
                    </Flex>
                    <Flex mt="auto" mb="42px" mx={{ base: '25px', xl: '71px' }} direction={{ base: 'column', xl: 'row' }}>
                        {tabs.map((item, index) => {
                            return (
                                <Flex
                                    w={{
                                        base: '100%',
                                        xl: 100 / tabs.length + '%',
                                    }}
                                    direction="column"
                                    key={index}
                                    mb={{ base: '15px', xl: '0px' }}
                                    mx="20px"
                                >
                                    <HStack>
                                        <Image boxSize="20px" src={process.env.PUBLIC_URL + '/assets/home/purpleCheck.svg'}></Image>
                                        <Text className={i_text_d} fontWeight="700">
                                            {t(item.title)}
                                        </Text>
                                    </HStack>

                                    <Text className={i_text_piece3} color="#6A5E86" mt="13px">
                                        {t(item.content)}
                                    </Text>
                                </Flex>
                            );
                        })}
                    </Flex>
                </Flex>
                {isMobile && (
                    <Flex w="100%" justifyContent="center">
                        <CustomButton
                            className={i_text_copy}
                            fontSize="12px !important"
                            w="299px"
                            h="51px"
                            variant="purple"
                            text={t('Swap Now')}
                            fontWeight="700"
                            mb="24px"
                            onClick={() => {
                                history.push('/trade/swap');
                            }}
                        ></CustomButton>
                    </Flex>
                )}
            </Card>
        </Flex>
    );
};

export default HomePiece4;
