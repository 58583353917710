import { createModel } from "@rematch/core";
import { BigNumber } from "bignumber.js";
import Web3 from "web3";
import { RootModel } from "../../..";
import { A_LONG_FUTURE_TIME, IZUMI_SWAP_CONFIG } from "../../../../../config/bizConfig";
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted";
import { ChainId } from "../../../../../types/mod";
import { toContractFeeNumber } from "../../../../../utils/funcs";
import { amount2Decimal, decimal2Amount } from "../../../../../utils/tokenMath";
import { getSortedTokenAddr, getSwapTokenAddress } from "../../../common/positionPoolHelper";
import { PathQueryResult } from "../../aggregator/utils";

import { calciZiLiquidityAmountDesired } from "../maths/liquidityMath";
import { point2PriceDecimal, priceDecimal2Point, PriceRoundingType } from "../../utils/priceMath";
import { getLiquidityForTap, getTapAddTransaction, getTapMintTransaction, searchA2B } from "../../tap/controller";

import { AddLiquidityRequest, LiquidityManagerContract, MintRequest } from "../../../../../types/abis/iZiSwap/LiquidityManager";
import { BoxContract } from "../../../../../types/abis/iZiSwap/Box";
import { TransactionReceipt } from "ethereum-abi-types-generator";
import { isGasToken } from "../../../../../config/tokens";
import { buildSendingParams } from "../../../../../utils/contractHelpers";
import { LiquidityDetail } from "../types";
import produce from "immer";
import { pointDeltaRoundingDown, pointDeltaRoundingUp } from "../../../farm/iZiSwap/price";
import { QueryConfig, TapMode, TapModeStatus, TapResultForMint } from "../../tap/types";
import { ToastLink } from "../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast";
import { getChain, getTxLink } from "../../../../../config/chains";

export interface iZiSwapMintForm {

    chainId: ChainId;

    tokenId?: string;

    token0: TokenInfoFormatted;
    token1: TokenInfoFormatted;
    fee: FeeTier;

    leftPoint: number;
    rightPoint: number;
    currentPoint: number;

    amount0Desired: BigNumber;
    amount1Desired: BigNumber;
    amount0DecimalDesired: number;
    amount1DecimalDesired: number;

    currentPrice0By1Decimal: number;
    lowerPrice0By1Decimal: number;
    upperPrice0By1Decimal: number;

    isLockToken0: boolean;

    spacingMapping: {[index: number]: number};

    // true for tap mode
    isTapMode: boolean;
    
    tapResultForMint?: TapResultForMint
    tapModeStatus: TapModeStatus
    
}
export interface InitiZiSwapMintFormParams {
    token0: TokenInfoFormatted;
    token1: TokenInfoFormatted;

    chainId: ChainId;
    currentPoint: number;
    fee: FeeTier;
    spacingMapping: {[index: number]: number};
    
    leftPoint?: number;
    rightPoint?: number;

    isTapMode: boolean;
    customOffset?: number;
}

export interface InitiZiSwapIncFormParams {
    liquidity:LiquidityDetail
    spacingMapping: {[index: number]: number};
    isTapMode: boolean;
    chainId: ChainId;
}

export interface AddMintFormTickParams {
    stepPositive: boolean;
    isUpper: boolean;
    currentPoint: number;
}

export interface SetMintFormCurrentPriceParams {
    chainId: ChainId;
    currentPoint: number;
    token0: TokenInfoFormatted;
    token1: TokenInfoFormatted;
    fee: FeeTier;
}

export interface SetMintFormTickPriceParams {
    price0By1Decimal: number;
    isUpper?: boolean;
    currentPoint: number;
}

export interface SetMintFormTickPriceTwoSideParams {
    lowerPrice0By1Decimal: number;
    upperPrice0By1Decimal: number;
    currentPoint: number;
}

export interface SetMintOrIncFormAmountDesiredParams {
    isDesired0: boolean;
    desiredAmountDecimal: number;
    currentPoint: number;
}

export interface TapCalculateParams {
    currentPoint: number,
    queryConfig: QueryConfig
    web3: Web3
    chainId: ChainId
}

export interface MintLiquidityFromIzumiParams {
    account: string;
    liquidityManagerContract?: LiquidityManagerContract;
    chainId: ChainId;
    gasPrice: number;
    onGoingCallback?:  (toastLink?: ToastLink) => void;
}


export interface MintLiquidityTapParams {
    tapResult: TapResultForMint
    account: string
    liquidityManagerContract?: LiquidityManagerContract
    web3: Web3
    chainId: ChainId
    gasPrice: number
    onGoingCallback?:  (toastLink?: ToastLink) => void
}

export interface AddLiquidityTapParams {
    tapResult: TapResultForMint
    liquidityManagerContract?: LiquidityManagerContract
    account: string
    web3: Web3
    chainId: ChainId
    gasPrice: number
    onGoingCallback?:  (toastLink?: ToastLink) => void
}

export interface BoxMintLiquidityFromIzumiParams {
    account: string;
    boxContract?: BoxContract;
    chainId: ChainId;
    gasPrice: number;
    onGoingCallback?:  (toastLink?: ToastLink) => void;
}

export interface IncLiquidityFromIzumiParams {
    account: string;
    liquidityManagerContract?: LiquidityManagerContract;
    chainId: ChainId;
    gasPrice: number;
    onGoingCallback?: (toastLink?: any) => void
}

export interface BoxIncLiquidityFromIzumiParams {
    account: string;
    boxContract?: BoxContract;
    chainId: ChainId;
    gasPrice: number;
    onGoingCallback?: (toastLink?: any) => void;
}

export interface SetFormTokenParams {
    tokenInfo: TokenInfoFormatted;
    chainId: ChainId;
    isUpper: boolean;
}

export const iZiSwapMintOrIncForm = createModel<RootModel>()({
    state: {
        token0: {},
        token1: {},
        isTapMode: false,
    } as iZiSwapMintForm,
    reducers: {
        saveiZiSwapMintOrAddForm: (state: iZiSwapMintForm, payload: iZiSwapMintForm) => {
            return { ...state, ...payload };
        },

        setTapModeStatus: (state: iZiSwapMintForm, tapModeStatus: TapModeStatus) => produce(state, draft => {
            draft.tapModeStatus = tapModeStatus
        }),

        clearForm: () => {
            return {
                token0: {},
                token1: {},
                isTapMode: false,
            } as iZiSwapMintForm;
        },
        
        setMintFormFee: (state: iZiSwapMintForm, fee: FeeTier) => produce(state, draft => {
            draft.fee = fee;
        }),
    
        setMintFormToken: (state: iZiSwapMintForm, mintTokenParams: SetFormTokenParams) => produce(state, draft => {
            const { isUpper, tokenInfo } = mintTokenParams;
            if (!isUpper && (!draft.token0.symbol || tokenInfo.symbol !== draft.token0.symbol)) {
                draft.token0 = tokenInfo;
            } else if (isUpper && (!draft.token1.symbol || tokenInfo.symbol !== draft.token1.symbol)) {
                draft.token1 = tokenInfo;
            }
        }),
    
        setMintFormTapMode: (state: iZiSwapMintForm, isTapMode: boolean) => produce(state, draft => {
            draft.isTapMode = isTapMode;
        }),

        toggleTokenOrder: (state: iZiSwapMintForm) => produce(state, draft => {
            if (draft.isTapMode) {
                return
            }
            [draft.token0, draft.token1] = [draft.token1, draft.token0];
    
            [draft.amount0Desired, draft.amount1Desired] = [draft.amount1Desired, draft.amount0Desired];
            [draft.amount0DecimalDesired, draft.amount1DecimalDesired] = [draft.amount1DecimalDesired, draft.amount0DecimalDesired];
    
            [draft.lowerPrice0By1Decimal, draft.upperPrice0By1Decimal] = [1 / draft.upperPrice0By1Decimal, 1 / draft.lowerPrice0By1Decimal];
            
            draft.isLockToken0 = !draft.isLockToken0;
        }),
        initMintForm: (state: iZiSwapMintForm, initMintFormParams: InitiZiSwapMintFormParams) => {
            const { token0, token1, fee, chainId, currentPoint, spacingMapping, isTapMode, customOffset } = initMintFormParams;
            if (!token0.symbol || !token1.symbol || !fee || !chainId || !spacingMapping) {
                return;
            }
            const currentPrice0By1Decimal = point2PriceDecimal(token0, token1, currentPoint)
            const lowerPrice0By1DecimalRaw = currentPrice0By1Decimal / ((100 + (customOffset?? IZUMI_SWAP_CONFIG.ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT)) / 100);
            const upperPrice0By1DecimalRaw = currentPrice0By1Decimal * (100 + (customOffset?? IZUMI_SWAP_CONFIG.ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT)) / 100;
            const token0Address = getSwapTokenAddress(token0)
            const token1Address = getSwapTokenAddress(token1)
            let leftPointRaw = 0;
            let rightPointRaw = 0;
            if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
                leftPointRaw = priceDecimal2Point(token0, token1, lowerPrice0By1DecimalRaw, PriceRoundingType.PRICE_ROUNDING_DOWN)
                rightPointRaw = priceDecimal2Point(token0, token1, upperPrice0By1DecimalRaw, PriceRoundingType.PRICE_ROUNDING_UP)
            } else {
                rightPointRaw = priceDecimal2Point(token0, token1, lowerPrice0By1DecimalRaw, PriceRoundingType.PRICE_ROUNDING_UP)
                leftPointRaw = priceDecimal2Point(token0, token1, upperPrice0By1DecimalRaw, PriceRoundingType.PRICE_ROUNDING_DOWN)
            }

            const pointDelta = spacingMapping[fee]
            const leftPoint = pointDeltaRoundingDown(leftPointRaw, pointDelta)
            const rightPoint = pointDeltaRoundingUp(rightPointRaw, pointDelta)

            const leftPointPrice0By1Decimal = point2PriceDecimal(token0, token1, leftPoint)
            const rightPointPrice0By1Decimal = point2PriceDecimal(token0, token1, rightPoint)
            const lowerPrice0By1Decimal = Math.min(leftPointPrice0By1Decimal, rightPointPrice0By1Decimal)
            const upperPrice0By1Decimal = Math.max(leftPointPrice0By1Decimal, rightPointPrice0By1Decimal)

            const mintFormNew = {
                chainId,
                token0,
                token1,
                fee,
                leftPoint,
                rightPoint,
                currentPoint,

                amount0DecimalDesired: 0,
                amount0Desired: new BigNumber(0),
                amount1DecimalDesired: 0,
                amount1Desired: new BigNumber(0),

                lowerPrice0By1Decimal,
                upperPrice0By1Decimal,

                isLockToken0: true,

                spacingMapping,
                isTapMode: isTapMode,
                tapModeStatus: TapModeStatus.Modified,
            } as iZiSwapMintForm;

            return mintFormNew
        },
        
        initIncForm: (state: iZiSwapMintForm, initIncFormParams: InitiZiSwapIncFormParams) => {
            const {liquidity, spacingMapping, isTapMode, chainId} = initIncFormParams
            const currentPoint = liquidity.currentPt
            const leftPoint = Number(liquidity.leftPt)
            const rightPoint = Number(liquidity.rightPt)
            const token0 = liquidity.tokenX
            const token1 = liquidity.tokenY
            const fee = liquidity.fee

            // token0 is tokenX
            const lowerPrice0By1Decimal = point2PriceDecimal(token0, token1, leftPoint)
            const upperPrice0By1Decimal = point2PriceDecimal(token0, token1, rightPoint)

            const incFormNew = {
                chainId,

                tokenId: liquidity.tokenId,
                token0,
                token1,
                fee,
                leftPoint,
                rightPoint,
                currentPoint,

                amount0DecimalDesired: 0,
                amount0Desired: new BigNumber(0),
                amount1DecimalDesired: 0,
                amount1Desired: new BigNumber(0),

                lowerPrice0By1Decimal,
                upperPrice0By1Decimal,

                spacingMapping,
                isTapMode: isTapMode,
                tapModeStatus: TapModeStatus.Modified
            } as iZiSwapMintForm;
            return incFormNew
        },
    },
    effects: (dispatch) => ({
        addMintFormTick(addMintFormTickParams: AddMintFormTickParams, rootState): void {
            const mintForm = {...rootState.iZiSwapMintOrIncForm}
            const tickStep = mintForm.spacingMapping[mintForm.fee] * (addMintFormTickParams.stepPositive ? 1 : -1);

            const token0Address = getSwapTokenAddress(mintForm.token0)
            const token1Address = getSwapTokenAddress(mintForm.token1)

            if (addMintFormTickParams.isUpper) {
                // update upperPrice0By1Decimal
                if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
                    mintForm.rightPoint += tickStep;
                } else {
                    mintForm.leftPoint -= tickStep;
                }
            } else {
                if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
                    mintForm.leftPoint += tickStep;
                } else {
                    mintForm.rightPoint -= tickStep;
                }
            }
            mintForm.currentPoint = addMintFormTickParams.currentPoint
            const leftDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.leftPoint)
            const rightDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.rightPoint)

            mintForm.lowerPrice0By1Decimal = Math.min(leftDecimalPrice, rightDecimalPrice)
            mintForm.upperPrice0By1Decimal = Math.max(leftDecimalPrice, rightDecimalPrice)
            if (mintForm.isTapMode) {
                mintForm.tapModeStatus = TapModeStatus.Modified
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            } else {
                if (mintForm.isLockToken0) {
                    mintForm.amount1Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount0Desired, true, mintForm.token0, mintForm.token1
                    )
                    mintForm.amount1DecimalDesired = amount2Decimal(mintForm.amount1Desired, mintForm.token1) as number
                } else {
                    mintForm.amount0Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount1Desired, false, mintForm.token0, mintForm.token1
                    )

                    mintForm.amount0DecimalDesired = amount2Decimal(mintForm.amount0Desired, mintForm.token0) as number
                }
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            }
        },

        setCurrentPointUnTap(setMintFormCurrentPriceParams: SetMintFormCurrentPriceParams, rootState): void {
            const mintForm = {...rootState.iZiSwapMintOrIncForm}
            const {token0, token1, fee, chainId} = setMintFormCurrentPriceParams;
            if (
                chainId !== mintForm.chainId ||
                token0.symbol !== mintForm.token0.symbol || 
                token1.symbol !== mintForm.token1.symbol ||
                fee !== mintForm.fee ||
                mintForm.isTapMode
            ) {
                // discard data out of time or tap mode
                return
            }

            mintForm.currentPoint = setMintFormCurrentPriceParams.currentPoint
            mintForm.currentPrice0By1Decimal = point2PriceDecimal(
                mintForm.token0,
                mintForm.token1,
                mintForm.currentPoint
            )

            const leftDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.leftPoint)
            const rightDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.rightPoint)

            mintForm.lowerPrice0By1Decimal = Math.min(leftDecimalPrice, rightDecimalPrice)
            mintForm.upperPrice0By1Decimal = Math.max(leftDecimalPrice, rightDecimalPrice)

            if (mintForm.isLockToken0) {
                mintForm.amount1Desired = calciZiLiquidityAmountDesired(
                    mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount0Desired, true, mintForm.token0, mintForm.token1
                )
                mintForm.amount1DecimalDesired = amount2Decimal(mintForm.amount1Desired, mintForm.token1) as number
            } else {
                mintForm.amount0Desired = calciZiLiquidityAmountDesired(
                    mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount1Desired, false, mintForm.token0, mintForm.token1
                )

                mintForm.amount0DecimalDesired = amount2Decimal(mintForm.amount0Desired, mintForm.token0) as number
            }
            dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
        },

        setMintFormTickPrice(setMintFormTickPriceParams: SetMintFormTickPriceParams, rootState): void {
            const mintForm = {...rootState.iZiSwapMintOrIncForm}

            let leftPoint = mintForm.leftPoint
            let rightPoint = mintForm.rightPoint
            const token0Address = getSwapTokenAddress(mintForm.token0)
            const token1Address = getSwapTokenAddress(mintForm.token1)
            if (setMintFormTickPriceParams.isUpper) {
                if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
                    rightPoint = priceDecimal2Point(mintForm.token0, mintForm.token1, setMintFormTickPriceParams.price0By1Decimal, PriceRoundingType.PRICE_ROUNDING_UP);
                } else {
                    leftPoint = priceDecimal2Point(mintForm.token0, mintForm.token1, setMintFormTickPriceParams.price0By1Decimal, PriceRoundingType.PRICE_ROUNDING_DOWN);
                }
                mintForm.upperPrice0By1Decimal = setMintFormTickPriceParams.price0By1Decimal
            } else {
                if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
                    leftPoint = priceDecimal2Point(mintForm.token0, mintForm.token1, setMintFormTickPriceParams.price0By1Decimal, PriceRoundingType.PRICE_ROUNDING_DOWN);
                } else {
                    rightPoint = priceDecimal2Point(mintForm.token0, mintForm.token1, setMintFormTickPriceParams.price0By1Decimal, PriceRoundingType.PRICE_ROUNDING_UP);
                }
                mintForm.lowerPrice0By1Decimal = setMintFormTickPriceParams.price0By1Decimal
            }
            const pointDelta = mintForm.spacingMapping[mintForm.fee]
            leftPoint = pointDeltaRoundingDown(leftPoint, pointDelta)
            rightPoint = pointDeltaRoundingUp(rightPoint, pointDelta)
            mintForm.leftPoint = leftPoint
            mintForm.rightPoint = rightPoint

            const leftDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.leftPoint)
            const rightDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.rightPoint)

            mintForm.lowerPrice0By1Decimal = Math.min(leftDecimalPrice, rightDecimalPrice)
            mintForm.upperPrice0By1Decimal = Math.max(leftDecimalPrice, rightDecimalPrice)

            if (mintForm.isTapMode) {
                mintForm.tapModeStatus = TapModeStatus.Modified
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            } else {
                if (mintForm.isLockToken0) {
                    mintForm.amount1Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount0Desired, true, mintForm.token0, mintForm.token1
                    )
                    mintForm.amount1DecimalDesired = amount2Decimal(mintForm.amount1Desired, mintForm.token1) as number
                } else {
                    mintForm.amount0Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount1Desired, false, mintForm.token0, mintForm.token1
                    )

                    mintForm.amount0DecimalDesired = amount2Decimal(mintForm.amount0Desired, mintForm.token0) as number
                }
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            }
        },

        setMintFormTickPriceTwoSide(setMintFormTickPriceTwoSideParams: SetMintFormTickPriceTwoSideParams, rootState): void {

            const mintForm = {...rootState.iZiSwapMintOrIncForm}
            const token0Address = getSwapTokenAddress(mintForm.token0)
            const token1Address = getSwapTokenAddress(mintForm.token1)
            mintForm.lowerPrice0By1Decimal = setMintFormTickPriceTwoSideParams.lowerPrice0By1Decimal
            mintForm.upperPrice0By1Decimal = setMintFormTickPriceTwoSideParams.upperPrice0By1Decimal
            if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
                // point is price0By1
                mintForm.leftPoint = priceDecimal2Point(mintForm.token0, mintForm.token1, mintForm.lowerPrice0By1Decimal, PriceRoundingType.PRICE_ROUNDING_DOWN)
                mintForm.rightPoint = priceDecimal2Point(mintForm.token0, mintForm.token1, mintForm.upperPrice0By1Decimal, PriceRoundingType.PRICE_ROUNDING_UP)
            } else {
                // point is price1By0
                mintForm.leftPoint = priceDecimal2Point(mintForm.token1, mintForm.token0, 1 / mintForm.upperPrice0By1Decimal, PriceRoundingType.PRICE_ROUNDING_DOWN)
                mintForm.rightPoint = priceDecimal2Point(mintForm.token1, mintForm.token0, 1 / mintForm.lowerPrice0By1Decimal, PriceRoundingType.PRICE_ROUNDING_UP)
            }
            const pointDelta = mintForm.spacingMapping[mintForm.fee]
            mintForm.leftPoint = pointDeltaRoundingDown(mintForm.leftPoint, pointDelta)
            mintForm.rightPoint = pointDeltaRoundingUp(mintForm.rightPoint, pointDelta)

            const leftDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.leftPoint)
            const rightDecimalPrice = point2PriceDecimal(mintForm.token0, mintForm.token1, mintForm.rightPoint)

            mintForm.lowerPrice0By1Decimal = Math.min(leftDecimalPrice, rightDecimalPrice)
            mintForm.upperPrice0By1Decimal = Math.max(leftDecimalPrice, rightDecimalPrice)

            if (mintForm.isTapMode) {
                mintForm.tapModeStatus = TapModeStatus.Modified
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            } else {
                if (mintForm.isLockToken0) {
                    mintForm.amount1Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount0Desired, true, mintForm.token0, mintForm.token1
                    )
                    mintForm.amount1DecimalDesired = amount2Decimal(mintForm.amount1Desired, mintForm.token1) as number
                } else {
                    mintForm.amount0Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount1Desired, false, mintForm.token0, mintForm.token1
                    )

                    mintForm.amount0DecimalDesired = amount2Decimal(mintForm.amount0Desired, mintForm.token0) as number
                }
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            }
        },
        setMintFormAmountDesired(params: SetMintOrIncFormAmountDesiredParams, rootState): void {

            const mintForm = {...rootState.iZiSwapMintOrIncForm}
            const isDesired0 = params.isDesired0
            mintForm.currentPoint = params.currentPoint
            const desiredAmountDecimal = params.desiredAmountDecimal
            
            if (isDesired0) {
                mintForm.isLockToken0 = true;
                mintForm.amount0DecimalDesired = Number(desiredAmountDecimal);
                const amount0Desired = decimal2Amount(new BigNumber(mintForm.amount0DecimalDesired), mintForm.token0) ?? new BigNumber(0);
                mintForm.amount0Desired = amount0Desired;

            } else {
                mintForm.isLockToken0 = false;
                mintForm.amount1DecimalDesired = Number(desiredAmountDecimal);
                const amount1Desired = decimal2Amount(new BigNumber(mintForm.amount1DecimalDesired), mintForm.token1) ?? new BigNumber(0);
                mintForm.amount1Desired = amount1Desired;
            }
        
            if (mintForm.isTapMode) {
                mintForm.tapModeStatus = TapModeStatus.Modified
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            } else {
                if (mintForm.isLockToken0) {
                    mintForm.amount1Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount0Desired, true, mintForm.token0, mintForm.token1
                    )
                    mintForm.amount1DecimalDesired = amount2Decimal(mintForm.amount1Desired, mintForm.token1) as number
                } else {
                    mintForm.amount0Desired = calciZiLiquidityAmountDesired(
                        mintForm.leftPoint, mintForm.rightPoint, mintForm.currentPoint, mintForm.amount1Desired, false, mintForm.token0, mintForm.token1
                    )

                    mintForm.amount0DecimalDesired = amount2Decimal(mintForm.amount0Desired, mintForm.token0) as number
                }
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            }
        },

        async tapCalculate(params: TapCalculateParams, rootState): Promise<void> {

            const mintForm = {...rootState.iZiSwapMintOrIncForm} as iZiSwapMintForm
            if (mintForm.isTapMode) {

                const currentPoint = params.currentPoint
                if (mintForm.amount0DecimalDesired > 0 || mintForm.amount1DecimalDesired > 0) {
                    const originLiquidityResult = getLiquidityForTap(
                        mintForm.token0,
                        mintForm.token1,
                        mintForm.amount0Desired,
                        mintForm.amount1Desired,
                        mintForm.leftPoint,
                        mintForm.rightPoint,
                        currentPoint
                    )

                    const liquidity0 = new BigNumber(originLiquidityResult.liquidityA)
                    const liquidity1 = new BigNumber(originLiquidityResult.liquidityB)
                    const has0 = originLiquidityResult.hasA
                    const has1 = originLiquidityResult.hasB

                    const delta = liquidity0.minus(liquidity1).abs()
                    const maxLiquidity = BigNumber.max(liquidity0, liquidity1)

                    if (delta.gt(maxLiquidity.div(100)) || !has0 || !has1) {
                        // need tap
                        if (liquidity0.gt(liquidity1) && has1 || !has0) {
                            // token0 is tokenA
                            const result = await searchA2B(
                                params.queryConfig,
                                params.chainId,
                                params.web3,
                                mintForm.token0,
                                mintForm.token1,
                                mintForm.amount0Desired,
                                mintForm.amount1Desired,
                                toContractFeeNumber(mintForm.fee),
                                mintForm.leftPoint,
                                mintForm.rightPoint,
                                currentPoint
                            )
                            mintForm.tapResultForMint = {
                                liquidity0: result.liquidityA,
                                liquidity1: result.liquidityB,
                                liquidity: result.liquidity,
                                amount0: result.amountA,
                                amount1: result.amountB,
                                leftPoint: result.leftPoint,
                                rightPoint: result.rightPoint,
                                newPoint: result.newPoint,
                                pathQueryResult: result.pathQueryResult,
                                has0: result.hasA,
                                has1: result.hasB,
                                tapMode: result.swapAmountIn === '0' ? TapMode.NoTap : TapMode.Tap0To1,
                                swapAmountIn: result.swapAmountIn,
                                swapAmountOut: result.swapAmountOut,
                                originToken0Amount: result.originAmountA,
                                originToken1Amount: result.originAmountB,
                                tapResult: result,
                            }
                        } else {
                            // token1 is tokenA
                            const result = await searchA2B(
                                params.queryConfig,
                                params.chainId,
                                params.web3,
                                mintForm.token1,
                                mintForm.token0,
                                mintForm.amount1Desired,
                                mintForm.amount0Desired,
                                toContractFeeNumber(mintForm.fee),
                                mintForm.leftPoint,
                                mintForm.rightPoint,
                                currentPoint
                            )
                            mintForm.tapResultForMint = {
                                liquidity0: result.liquidityB,
                                liquidity1: result.liquidityA,
                                liquidity: result.liquidity,
                                amount0: result.amountB,
                                amount1: result.amountA,
                                leftPoint: result.leftPoint,
                                rightPoint: result.rightPoint,
                                newPoint: result.newPoint,
                                pathQueryResult: result.pathQueryResult,
                                has0: result.hasB,
                                has1: result.hasA,
                                tapMode: result.swapAmountIn === '0' ? TapMode.NoTap : TapMode.Tap1To0,
                                swapAmountIn: result.swapAmountIn,
                                swapAmountOut: result.swapAmountOut,
                                originToken1Amount: result.originAmountA,
                                originToken0Amount: result.originAmountB,
                                tapResult: result,
                            }
                        }
                    } else {
                        // not need tap
                        const result = originLiquidityResult
                        mintForm.tapResultForMint = {
                            liquidity0: result.liquidityA,
                            liquidity1: result.liquidityB,
                            liquidity: result.liquidity,
                            amount0: result.amountA,
                            amount1: result.amountB,
                            leftPoint: result.leftPoint,
                            rightPoint: result.rightPoint,
                            newPoint: result.newPoint,
                            pathQueryResult: result.pathQueryResult,
                            has0: result.hasA,
                            has1: result.hasB,
                            tapMode: TapMode.NoTap,
                            swapAmountIn: result.swapAmountIn,
                            swapAmountOut: result.swapAmountOut,
                            originToken0Amount: result.originAmountA,
                            originToken1Amount: result.originAmountB,
                            tapResult: result,
                        }
                    }
                }
                mintForm.tapModeStatus = TapModeStatus.Complete
                dispatch.iZiSwapMintOrIncForm.saveiZiSwapMintOrAddForm(mintForm)
            }
        },

        async mintBoxLiquidity(params: BoxMintLiquidityFromIzumiParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.boxContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const { account, boxContract, chainId, onGoingCallback } = params;
            const mintForm = rootState.iZiSwapMintOrIncForm
            const wrapToken0Address = mintForm.token0.wrapTokenAddress ?? mintForm.token0.address
            const wrapToken1Address = mintForm.token1.wrapTokenAddress ?? mintForm.token1.address

            const token0IsWrap = (!mintForm.token0.wrapTokenAddress) ? false : true
            const token1IsWrap = (!mintForm.token1.wrapTokenAddress) ? false : true
            const sortedTokenAddr = getSortedTokenAddr(wrapToken0Address, wrapToken1Address);
            const isFlipped = sortedTokenAddr[0] !== wrapToken0Address;

            const tokenXIsWrap = isFlipped ? token1IsWrap : token0IsWrap;
            const tokenYIsWrap = isFlipped ? token0IsWrap : token1IsWrap;

            const amount0Desired = isFlipped ? mintForm.amount1Desired : mintForm.amount0Desired;
            const amount1Desired = isFlipped ? mintForm.amount0Desired : mintForm.amount1Desired;

            const mintRequest = {
                tokenX: sortedTokenAddr[0], // here tokenX is address
                tokenY: sortedTokenAddr[1],
                fee: toContractFeeNumber(mintForm.fee),
                pl: mintForm.leftPoint,
                pr: mintForm.rightPoint,
                xLim: (new BigNumber(amount0Desired)).toFixed(0),
                yLim: (new BigNumber(amount1Desired)).toFixed(0),
                miner: account,
                amountXMin: (new BigNumber(amount0Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                amountYMin: (new BigNumber(amount1Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                deadline: String(A_LONG_FUTURE_TIME),
            } as MintRequest;

            mintRequest.amountXMin = new BigNumber(mintRequest.amountXMin).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR).toFixed(0)
            mintRequest.amountYMin = new BigNumber(mintRequest.amountYMin).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR).toFixed(0)

            let value = '0';
            if (isGasToken(mintForm.token0, chainId)) {
                value = isFlipped ? mintRequest.yLim : mintRequest.xLim;
            }
            if (isGasToken(mintForm.token1, chainId)) {
                value = isFlipped ? mintRequest.xLim : mintRequest.yLim;
            }
            const chain  = getChain(chainId);
            const toastLink = {} as ToastLink;

            return boxContract.methods.mint(
                mintRequest, tokenXIsWrap, tokenYIsWrap
            ).send(
                buildSendingParams(
                    params.chainId, 
                    { 
                        from: account, 
                        maxFeePerGas: params.gasPrice,
                        value
                    },
                    params.gasPrice
                ) as any)
            .on(
                'transactionHash',
                (hash: string) => {
                    if (chain) {
                        toastLink.title = "View on " + chain.name;
                        toastLink.link = getTxLink(hash, chain);
                    }
                    if(typeof onGoingCallback !='undefined'){
                    onGoingCallback(toastLink);
                    }
                }
            );
        },

        async mintLiquidity(params: MintLiquidityFromIzumiParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.liquidityManagerContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const mintForm = rootState.iZiSwapMintOrIncForm
            const { account, liquidityManagerContract: positionManagerContract, chainId, onGoingCallback } = params;
            const sortedTokenAddr = getSortedTokenAddr(mintForm.token0.address, mintForm.token1.address);
            const isFlipped = sortedTokenAddr[0] !== mintForm.token0.address;

            const amount0Desired = isFlipped ? mintForm.amount1Desired : mintForm.amount0Desired;
            const amount1Desired = isFlipped ? mintForm.amount0Desired : mintForm.amount1Desired;

            const mintRequest = {
                tokenX: sortedTokenAddr[0], // here tokenX is address
                tokenY: sortedTokenAddr[1],
                fee: toContractFeeNumber(mintForm.fee),
                pl: mintForm.leftPoint,
                pr: mintForm.rightPoint,
                xLim: (new BigNumber(amount0Desired)).toFixed(0),
                yLim: (new BigNumber(amount1Desired)).toFixed(0),
                miner: account,
                amountXMin: (new BigNumber(amount0Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                amountYMin: (new BigNumber(amount1Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                deadline: String(A_LONG_FUTURE_TIME),
            } as MintRequest;

            let value = '0';
            if (isGasToken(mintForm.token0, chainId)) {
                value = isFlipped ? mintRequest.yLim : mintRequest.xLim;
            }
            if (isGasToken(mintForm.token1, chainId)) {
                value = isFlipped ? mintRequest.xLim : mintRequest.yLim;
            }

            const chain  = getChain(chainId);
            const toastLink = {} as ToastLink;
            if ( value === '0') {
                return positionManagerContract.methods.mint(mintRequest).send(
                    buildSendingParams(
                        params.chainId,
                        {
                            from: account,
                            maxFeePerGas: params.gasPrice,
                            value: '0'
                        },
                        params.gasPrice
                    ) as any
                ).on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = "View on " + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if(typeof onGoingCallback !='undefined'){
                        onGoingCallback(toastLink);
                        }
                    }
                );
            } else {
                const mintMultiCall = [];
                mintMultiCall.push(positionManagerContract.methods.mint(mintRequest).encodeABI());
                mintMultiCall.push(positionManagerContract.methods.refundETH().encodeABI());
                return positionManagerContract.methods.multicall(mintMultiCall).send(
                    buildSendingParams(
                        params.chainId, 
                        { 
                            from: account, 
                            maxFeePerGas: params.gasPrice,
                            value
                        },
                        params.gasPrice
                    ) as any
                ).on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = "View on " + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if(typeof onGoingCallback !='undefined'){
                        onGoingCallback(toastLink);
                        }
                    }
                );
            }
        },

        async incBoxLiquidity(params: BoxIncLiquidityFromIzumiParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.boxContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const incForm = rootState.iZiSwapMintOrIncForm
            const { account, boxContract ,chainId,onGoingCallback} = params;

            const wrapToken0Address = incForm.token0.wrapTokenAddress ?? incForm.token0.address
            const wrapToken1Address = incForm.token1.wrapTokenAddress ?? incForm.token1.address

            const token0IsWrap = (!incForm.token0.wrapTokenAddress) ? false : true
            const token1IsWrap = (!incForm.token1.wrapTokenAddress) ? false : true
            const sortedTokenAddr = getSortedTokenAddr(wrapToken0Address, wrapToken1Address);
            const isFlipped = sortedTokenAddr[0] !== wrapToken0Address;

            const tokenXIsWrap = isFlipped ? token1IsWrap : token0IsWrap;
            const tokenYIsWrap = isFlipped ? token0IsWrap : token1IsWrap;

            const tokenXAddress = sortedTokenAddr[0]
            const tokenYAddress = sortedTokenAddr[1]

            const amount0Desired = isFlipped ? incForm.amount1Desired : incForm.amount0Desired;
            const amount1Desired = isFlipped ? incForm.amount0Desired : incForm.amount1Desired;

            const factor = IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR * IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR

            const incRequest: AddLiquidityRequest = {
                lid: incForm.tokenId as string,
                xLim: (new BigNumber(amount0Desired)).toFixed(0),
                yLim: (new BigNumber(amount1Desired)).toFixed(0),
                amountXMin: (new BigNumber(amount0Desired).multipliedBy(factor)).toFixed(0),
                amountYMin: (new BigNumber(amount1Desired).multipliedBy(factor)).toFixed(0),
                deadline: String(A_LONG_FUTURE_TIME),
            };
            let value = '0';
            if (isGasToken(incForm.token0, params.chainId)) {
                value = isFlipped ? incRequest.yLim : incRequest.xLim;
            }
            if (isGasToken(incForm.token1, params.chainId)) {
                value = isFlipped ? incRequest.xLim : incRequest.yLim;
            }
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            return boxContract.methods.addLiquidity(incRequest, tokenXAddress, tokenYAddress, tokenXIsWrap, tokenYIsWrap).send(
                buildSendingParams(
                    params.chainId,
                    {
                        from: account,
                        maxFeePerGas: params.gasPrice,
                        value
                    },
                    params.gasPrice
                ) as any
            ).on(
                'transactionHash',
                (hash: string) => {
                    if (chain) {
                        toastLink.title = 'View on ' + chain.name;
                        toastLink.link = getTxLink(hash, chain);
                    }
                    if (typeof onGoingCallback != 'undefined') {
                        onGoingCallback(toastLink);
                    }
                }
            );
            
        },
        async incLiquidity(params: IncLiquidityFromIzumiParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.liquidityManagerContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const incForm = rootState.iZiSwapMintOrIncForm
            const { account, liquidityManagerContract: positionManagerContract ,chainId, onGoingCallback} = params;

            const sortedTokenAddr = getSortedTokenAddr(incForm.token0.address, incForm.token1.address);
            const isFlipped = sortedTokenAddr[0] !== incForm.token0.address;

            const amount0Desired = isFlipped ? incForm.amount1Desired : incForm.amount0Desired;
            const amount1Desired = isFlipped ? incForm.amount0Desired : incForm.amount1Desired;

            const incRequest: AddLiquidityRequest = {
                lid: incForm.tokenId as string,
                xLim: (new BigNumber(amount0Desired)).toFixed(0),
                yLim: (new BigNumber(amount1Desired)).toFixed(0),
                amountXMin: (new BigNumber(amount0Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                amountYMin: (new BigNumber(amount1Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                deadline: String(A_LONG_FUTURE_TIME),
            };
            let value = '0';
            if (isGasToken(incForm.token0, params.chainId)) {
                value = isFlipped ? incRequest.yLim : incRequest.xLim;
            }
            if (isGasToken(incForm.token1, params.chainId)) {
                value = isFlipped ? incRequest.xLim : incRequest.yLim;
            }
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            if ( value === '0') {
                return positionManagerContract.methods.addLiquidity(incRequest).send(
                    buildSendingParams(
                        params.chainId,
                        {
                            from: account,
                            maxFeePerGas: params.gasPrice,
                            value: '0'
                        },
                        params.gasPrice
                    ) as any
                ).on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if (typeof onGoingCallback != 'undefined') {
                            onGoingCallback(toastLink);
                        }
                    }
                )
            } else {
                const mintMultiCall = [];
                mintMultiCall.push(positionManagerContract.methods.addLiquidity(incRequest).encodeABI());
                mintMultiCall.push(positionManagerContract.methods.refundETH().encodeABI());
                return positionManagerContract.methods.multicall(mintMultiCall).send(
                        buildSendingParams(
                            params.chainId,
                            {
                                from: account,
                                maxFeePerGas: params.gasPrice,
                                value
                            },
                            params.gasPrice
                        ) as any
                    ).on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if (typeof onGoingCallback != 'undefined') {
                            onGoingCallback(toastLink);
                        }
                    }
                )
            }
        },

        async mintLiquidityTap(params: MintLiquidityTapParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.chainId || !params.tapResult) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const chain  = getChain(params.chainId);
            const toastLink = {} as ToastLink;
            const mintForm = rootState.iZiSwapMintOrIncForm
            const tapResultForMint = params.tapResult
            if (tapResultForMint.tapMode === TapMode.NoTap) {

                const { account, liquidityManagerContract: positionManagerContract, chainId, onGoingCallback } = params;
                if (!positionManagerContract) {
                    return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
                }
                const sortedTokenAddr = getSortedTokenAddr(mintForm.token0.address, mintForm.token1.address);
                const isFlipped = sortedTokenAddr[0] !== mintForm.token0.address;
    
                const amount0Desired = isFlipped ? tapResultForMint.amount1: tapResultForMint.amount0;
                const amount1Desired = isFlipped ? tapResultForMint.amount0 : tapResultForMint.amount1;
    
                const mintRequest = {
                    tokenX: sortedTokenAddr[0], // here tokenX is address
                    tokenY: sortedTokenAddr[1],
                    fee: toContractFeeNumber(mintForm.fee),
                    pl: mintForm.leftPoint,
                    pr: mintForm.rightPoint,
                    xLim: (new BigNumber(amount0Desired)).toFixed(0),
                    yLim: (new BigNumber(amount1Desired)).toFixed(0),
                    miner: account,
                    amountXMin: (new BigNumber(amount0Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                    amountYMin: (new BigNumber(amount1Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                    deadline: String(A_LONG_FUTURE_TIME),
                } as MintRequest;

                console.log('mint request: ', mintRequest)
    
                let value = '0';
                if (isGasToken(mintForm.token0, chainId)) {
                    value = isFlipped ? mintRequest.yLim : mintRequest.xLim;
                }
                if (isGasToken(mintForm.token1, chainId)) {
                    value = isFlipped ? mintRequest.xLim : mintRequest.yLim;
                }
    
                if ( value === '0') {
                    return positionManagerContract.methods.mint(mintRequest).send(
                        buildSendingParams(
                            params.chainId,
                            {
                                from: account,
                                value: '0',
                                maxFeePerGas: params.gasPrice
                            },
                            params.gasPrice
                        ) as any
                    );
                } else {
                    const mintMultiCall = [];
                    mintMultiCall.push(positionManagerContract.methods.mint(mintRequest).encodeABI());
                    mintMultiCall.push(positionManagerContract.methods.refundETH().encodeABI());
                    return positionManagerContract.methods.multicall(mintMultiCall).send(
                        buildSendingParams(
                            params.chainId, 
                            { 
                                from: account, 
                                maxFeePerGas: params.gasPrice,
                                value
                            },
                            params.gasPrice
                        ) as any)
                    .on(
                        'transactionHash',
                        (hash: string) => {
                            if (chain) {
                                toastLink.title = "View on " + chain.name;
                                toastLink.link = getTxLink(hash, chain);
                            }
                            if(typeof onGoingCallback !='undefined'){
                            onGoingCallback(toastLink);
                            }
                        }
                    );
                }
            } else {
                const tapResult = tapResultForMint.tapResult
                const { account,  chainId, web3, onGoingCallback, gasPrice } = params;
                const tokenA = tapResultForMint.tapMode === TapMode.Tap0To1? mintForm.token0 : mintForm.token1
                const tokenB = tapResultForMint.tapMode === TapMode.Tap0To1? mintForm.token1 : mintForm.token0
                const {calling, options} = getTapMintTransaction(
                    chainId,
                    web3,
                    account,
                    tokenA,
                    tokenB,
                    toContractFeeNumber(mintForm.fee),
                    tapResult,
                    IZUMI_SWAP_CONFIG.TAP_SWAP_DESIRED_MIN_AMOUNT_FACTOR,
                    IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR,
                    5
                )
                return calling.send(
                    buildSendingParams(
                        params.chainId, 
                        { 
                            from: account, 
                            maxFeePerGas: gasPrice,
                            value: options.value ? options.value : '0'
                        },
                        params.gasPrice
                    ) as any)
                .on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = "View on " + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if(typeof onGoingCallback !='undefined'){
                            onGoingCallback(toastLink);
                        }
                    }
                );
            } 
        },

        async addLiquidityTap(params: AddLiquidityTapParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.chainId || !params.tapResult) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const chain  = getChain(params.chainId);
            const toastLink = {} as ToastLink;
            const incLiquidityForm = rootState.iZiSwapMintOrIncForm
            const tapResultForMint = params.tapResult
            if (tapResultForMint.tapMode === TapMode.NoTap) {

                const { liquidityManagerContract: positionManagerContract, account, onGoingCallback } = params;
                if (!positionManagerContract) {
                    return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
                }
                const sortedTokenAddr = getSortedTokenAddr(incLiquidityForm.token0.address, incLiquidityForm.token1.address);
                const isFlipped = sortedTokenAddr[0] !== incLiquidityForm.token0.address;
    
                const amount0Desired = isFlipped ? tapResultForMint.amount1 : tapResultForMint.amount0;
                const amount1Desired = isFlipped ? tapResultForMint.amount0 : tapResultForMint.amount1;
    
                const incRequest: AddLiquidityRequest = {
                    lid: incLiquidityForm.tokenId as string,
                    xLim: (new BigNumber(amount0Desired)).toFixed(0),
                    yLim: (new BigNumber(amount1Desired)).toFixed(0),
                    amountXMin: (new BigNumber(amount0Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                    amountYMin: (new BigNumber(amount1Desired).multipliedBy(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                    deadline: String(A_LONG_FUTURE_TIME),
                };
                let value = '0';
                if (isGasToken(incLiquidityForm.token0, params.chainId)) {
                    value = isFlipped ? incRequest.yLim : incRequest.xLim;
                }
                if (isGasToken(incLiquidityForm.token1, params.chainId)) {
                    value = isFlipped ? incRequest.xLim : incRequest.yLim;
                }
    
                if ( value === '0') {
                    return positionManagerContract.methods.addLiquidity(incRequest).send(
                        buildSendingParams(
                            params.chainId,
                            {
                                from: account,
                                maxFeePerGas: params.gasPrice,
                                value: '0'
                            },
                            params.gasPrice
                        ) as any
                    );
                } else {
                    const mintMultiCall = [];
                    mintMultiCall.push(positionManagerContract.methods.addLiquidity(incRequest).encodeABI());
                    mintMultiCall.push(positionManagerContract.methods.refundETH().encodeABI());
                    return positionManagerContract.methods.multicall(mintMultiCall).send(
                        buildSendingParams(
                            params.chainId,
                            {
                                from: account,
                                maxFeePerGas: params.gasPrice,
                                value
                            },
                            params.gasPrice
                        ) as any
                    );
                }
            } else {
                const tapResult = tapResultForMint.tapResult
                const { account,  chainId, web3, onGoingCallback, gasPrice } = params;
                const tokenA = tapResultForMint.tapMode === TapMode.Tap0To1? incLiquidityForm.token0 : incLiquidityForm.token1
                const tokenB = tapResultForMint.tapMode === TapMode.Tap0To1? incLiquidityForm.token1 : incLiquidityForm.token0
                const {calling, options} = getTapAddTransaction(
                    chainId,
                    web3,
                    incLiquidityForm.tokenId as string,
                    tokenA,
                    tokenB,
                    tapResult,
                    IZUMI_SWAP_CONFIG.TAP_SWAP_DESIRED_MIN_AMOUNT_FACTOR,
                    IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR,
                    5
                )
                return calling.send(
                    buildSendingParams(
                        params.chainId, 
                        { 
                            from: account, 
                            maxFeePerGas: gasPrice,
                            value: options.value ? options.value : '0'
                        },
                        params.gasPrice
                    ) as any)
                .on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = "View on " + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if(typeof onGoingCallback !='undefined'){
                            onGoingCallback(toastLink);
                        }
                    }
                );
            } 
        },

    }),


});
