import { useColorMode, useBreakpointValue, Flex, HStack, Image, Text } from '@chakra-ui/react';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { i_text_d, i_text_copy, i_text_copy_bold, i_text_piece3 } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import './HomePiece3.css';
import '../../Home.css';
import { useTranslation } from 'react-i18next';

export type CenterCardProps = {
    item: any;
    index: number;
};
const CenterCard: React.FC<CenterCardProps> = (props) => {
    const { item, index } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [cardFlow, setCardFlow] = useState(false);

    const history = useHistory();

    return (
        <Card
            className={cardFlow ? 'cardFlow' : 'cardLeave'}
            key={index}
            variant="base"
            w={{ base: '355px', md: '328px', xl: '232px' }}
            h={{ base: '168px', md: '334px', xl: '291px' }}
            mx={{ base: '13px', md: '6px' }}
            mb={{ base: '16px', xl: '44px' }}
            mt={{ base: '0px', xl: '86px' }}
            bg={colorTheme('linear-gradient(to bottom, #FBFBFB 10%, white 90%);', '#211834')}
            onMouseOver={() => {
                setCardFlow(true);
            }}
            onMouseLeave={() => {
                setCardFlow(false);
            }}
        >
            <Flex h="100%" direction={{ base: 'row', md: 'column' }} alignItems="center" justifyContent="center">
                {!isMobile && (
                    <Flex alignItems="center" justifyContent="center" direction="column" mt={{ base: '-20px', xl: '0px' }}>
                        <Image
                            w={{ base: '182px', xl: '233px' }}
                            h={{ base: '182px', xl: '215px' }}
                            mt={{ base: 'unset', xl: '-116px' }}
                            src={item.topIcon}
                        ></Image>
                    </Flex>
                )}
                <Flex w="212px" h="100%" ml="24px" direction="column" alignItems={{ base: 'start', md: 'center', xl: 'start' }}>
                    <HStack mt={{ base: '19px', md: 'unset' }}>
                        <Image src={item.leftIcon}></Image>
                        <Text className={i_text_d} fontWeight="600">
                            {t(item.title)}
                        </Text>
                    </HStack>

                    <Flex>
                        <Text className={i_text_piece3} color="#6A5E86" mt="18px" mr={{ base: '0px', xl: '21px' }} lineHeight="16px">
                            {t(item.content)}
                        </Text>
                    </Flex>
                    {isMobile && (
                        <Flex
                            alignItems="center"
                            mt="auto"
                            mb="20px"
                            onClick={() => {
                                history.push(item.route);
                            }}
                        >
                            <Text className={i_text_copy_bold} color="secondary.500">
                                {t(item.buttonText)}
                            </Text>
                            <Image
                                w="12px"
                                h="10px"
                                ml="8px"
                                src={process.env.PUBLIC_URL + '/assets/home/homePiece3/rightArrow.svg'}
                            ></Image>
                        </Flex>
                    )}
                </Flex>
                {isMobile && <Image w="119px" h="127px" src={item.topIcon}></Image>}
            </Flex>
            {!isMobile && (
                <Flex h="40px" justifyContent="center" mt="auto" mb="18px">
                    <CustomButton
                        className={i_text_copy}
                        w="190px"
                        h="40px"
                        variant="primary3"
                        text={t(item.buttonText)}
                        fontWeight="700"
                        onClick={() => {
                            if (item.route.slice(0, 4) === 'http') {
                                window.open(item.route);
                            } else {
                                history.push(item.route);
                            }
                        }}
                    ></CustomButton>
                </Flex>
            )}
        </Card>
    );
};
export default CenterCard;
