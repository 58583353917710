import { Center, Stack, Image, Text, useColorMode, Flex, useOutsideClick } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { useState, useEffect } from 'react';
import AccountCard from './AccountCard';
import ConnectorList from './ConnectorList';
import Identicon from '../Identicon';
import ToggleIcon from './ToggleIcon/ToggleIcon';
import CustomButton from '../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import GasBar from '../../iZUMi-UI-toolkit/src/components/Wallet/GasBar';
import NotConnected from '../../iZUMi-UI-toolkit/src/components/Wallet/NotConnected';
import { i_text_copy } from '../../style';
import truncateWalletAddress from '../../utils/truncateWalletAddress';
import { getColorThemeSelector } from '../../utils/funcs';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../state/store';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimension';
import useIsMobile from '../../hooks/useIsMobile';
import { useGasPrice } from '../../hooks/useGasPrice';
import { links } from '../../config/links';
import BuyiZiButton from '../../iZUMi-UI-toolkit/src/components/Buttons/BuyiZiButton/BuyiZiButton';
import ColorModeSelect from '../../iZUMi-UI-toolkit/src/components/Sidebar/ColorModeSelect/ColorModeSelect';
import CopyrightInfo from '../../iZUMi-UI-toolkit/src/components/Sidebar/CopyrightInfo/CopyrightInfo';
import { TokenSymbol, ChainId } from '../../types/mod';
import { formatNumber } from '../../utils/tokenMath';
import LanguageSelect from '../Sidebar/LanguageSelect/LanguageSelect';
import MediaList from '../Sidebar/MediaList/MediaList';
import React from 'react';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { getSafeTokenPrice } from '../../state/models/token/funcs';

const Wallet: React.FC = () => {
    const { account, chainId } = useWeb3WithDefault();
    const [showConnectors, setShowConnectors] = useState(false);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { token } = useSelector((state: RootState) => state);
    const { wallet } = useSelector((state: RootState) => state);
    const showNav = wallet.showNav;
    const setShowNav = dispatch.wallet.setShowNav;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const location = useLocation();
    const show = wallet.showModal && wallet.showModal(location);
    const { width } = useWindowDimensions();
    const limitWidth = 1600; //Used to define whether the user icon in the upper right corner is scaled
    useEffect(() => {
        dispatch.wallet.setModal(undefined as any, () => false);
    }, [chainId]);

    const mobile = useIsMobile();
    const { gasPrice } = useGasPrice();

    const isFocusWallet = React.useRef<any | null>(null);
    useOutsideClick({
        ref: isFocusWallet,
        handler: () => {
            mobile && setShowNav(false);
        },
    });

    return (
        <>
            {width >= 1000 && (
                <Stack
                    w={showNav ? '322px' : { base: '80px', xlp1: '79px' }}
                    display="hidden"
                    bg={colorTheme('#fbfbfb', '#140E28')}
                    transitionProperty="width"
                    transitionDuration="0.3s"
                    transitionTimingFunction="ease"
                    transitionDelay={showNav ? '0s' : '0.2s'}
                />
            )}

            <Stack
                w={showNav ? { base: '290px', sm: '322px' } : { base: '0', sm: '80px', xlp1: '79px' }}
                h={showNav ? '100%' : { base: '0', sm: '144px', xlp1: '100%' }}
                ref={isFocusWallet}
                boxShadow={colorTheme('base', 'unset')}
                borderRadius="4px"
                right={width >= limitWidth || showNav ? '0px' : mobile ? '-17px' : '10px'}
                top={width >= limitWidth || showNav ? '0px' : mobile ? '110px' : '17px'}
                pos="fixed"
                zIndex="8 !important"
                bg={colorTheme('white', '#292343')}
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                transitionDelay={showNav ? '0s' : '0.2s'}
            >
                <Stack w="100%" h="100%" position="relative">
                    <ToggleIcon
                        top="90px"
                        left="-24px"
                        hidden={!showNav && width <= limitWidth}
                        showNav={showNav}
                        setShowNav={setShowNav}
                        zIndex="0 !important"
                        visibility="visible"
                    />

                    {
                        // breaking symmetry to improve performance
                        // showNav && account && !waiting &&
                        <Stack
                            spacing="0"
                            alignItems="center"
                            py="49px"
                            overflowY="auto"
                            css={{
                                '&::-webkit-scrollbar': {
                                    display: 'none !important',
                                },
                            }}
                            h="100%"
                            hidden={showNav && account ? false : true}
                        >
                            <AccountCard />
                            {mobile && (
                                <Flex direction="column" mt="auto !important">
                                    <Flex w={'219px'} direction="row" align="center" mb={3} mx="auto">
                                        <ColorModeSelect showNav={true} />
                                        <LanguageSelect />
                                        <BuyiZiButton
                                            expand={true}
                                            tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                                            onClick={() => {
                                                if (chainId) {
                                                    window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                                                }
                                            }}
                                        />
                                    </Flex>

                                    <MediaList />
                                    <CopyrightInfo />
                                </Flex>
                            )}
                            {show && wallet.modal}
                        </Stack>
                    }

                    {showNav && !account && (
                        <Center w="322px" h="100%" pos="relative">
                            {showConnectors ? <ConnectorList /> : <NotConnected onConnect={() => setShowConnectors(true)} />}
                        </Center>
                    )}

                    {!showNav && account && (
                        <>
                            <Stack
                                w="100%"
                                h="100%"
                                spacing="10px"
                                alignItems="center"
                                pt={width >= limitWidth ? '41px' : '17px'}
                                position="absolute"
                                top="0px"
                                hidden={mobile}
                            >
                                <Identicon />
                                <Text className={i_text_copy} fontSize="12px !important">
                                    {truncateWalletAddress(account, 3, 2)}
                                </Text>
                                <ToggleIcon
                                    w="100%"
                                    h="28px"
                                    hidden={width > limitWidth}
                                    position="absolute"
                                    backgroundColor={colorTheme('#F0E8F5', '#3B365B')}
                                    borderRadius="0px 0px 0px 0px"
                                    bottom="0px"
                                    showNav={showNav}
                                    setShowNav={setShowNav}
                                    zIndex="0"
                                    visibility="visible"
                                />
                            </Stack>
                            <ToggleIcon
                                w="100%"
                                h="28px"
                                hidden={!mobile}
                                position="absolute"
                                backgroundColor={colorTheme('#F0E8F5', '#3B365B')}
                                borderRadius="14px"
                                bottom="0"
                                right="0"
                                showNav={showNav}
                                setShowNav={setShowNav}
                                zIndex="0"
                                visibility="visible"
                            />
                        </>
                    )}

                    {!showNav && !account && !mobile && (
                        <Stack spacing="30px" alignItems="center" pt={width >= limitWidth ? '41px' : '17px'}>
                            <Image ml="3px" w="50px" h="50px" src={process.env.PUBLIC_URL + '/assets/wallet/foldConnect.png'} />
                            <CustomButton
                                variant="purple"
                                borderRadius="3px"
                                className={i_text_copy}
                                fontSize="12px !important"
                                lineHeight="15px !important"
                                onClick={() => {
                                    setShowNav && setShowNav(true);
                                    setShowConnectors(true);
                                }}
                                w="65px"
                                h="30px"
                                text="Connect"
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <GasBar position="absolute" bottom="84px" right="7px" zIndex="9" hidden={showNav || !account || mobile} gasPrice={gasPrice} />
        </>
    );
};

export default Wallet;
