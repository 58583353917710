import { ChainId } from '../types/mod';
import { FARM_CONFIG } from '../config/bizConfig';
import { blocksPerDay, getScanUrl } from '../config/chains';
import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';

export const defaultPrecision = (x: number): number => {
    return x > 1 ? 6 : 5;
    if (x > 100000) {
        return 2;
    } else if (x > 10000) {
        return 2;
    } else if (x > 1000) {
        return 3;
    } else if (x > 100) {
        return 4;
    } else if (x > 10) {
        return 5;
    } else if (x > 1) {
        return 5;
    } else {
        return 5;
    }
};

export const identity = <T>(arg: T): T => arg;

export const isSameToken = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): boolean => {
    if (!tokenA || !tokenB) {
        return false;
    }
    if (tokenA.symbol === tokenB.symbol && tokenA.address === tokenB.address) {
        return true;
    }
    return false;
};

export type ColorThemeSelector<TLight = unknown, TDark = unknown> = (light: TLight, dark: TDark) => TLight | TDark;

export const getColorThemeSelector = <TLight = any, TDark = any>(colorMode: string): ColorThemeSelector<TLight, TDark> => {
    return <TLight, TDark>(light: TLight, dark: TDark) => (colorMode === 'dark' ? dark : light);
};

export const toContractFeeNumber = (fee: FeeTier): number => {
    // fee is percentage 1% -> 10000
    return Number(fee) * 10000;
};

export const toFeeNumber = (feeContractFormat: number): FeeTier => {
    // fee is amplified 10000 -> 1%
    return (feeContractFormat / 10000) as FeeTier;
};

export const feeRateToFeeNumber = (feeRate: number): FeeTier => {
    return (feeRate * 100) as FeeTier;
};

export const rewardDisplay = (rewardPerTime: number, chainId: ChainId, useTimestamp: boolean): number => {
    let value = rewardPerTime; // block/second
    if (FARM_CONFIG.REWARD_DISPLAY === 'day') {
        if (useTimestamp) {
            value = 24 * 60 * 60 * value;
        } else {
            value = blocksPerDay(chainId) * value;
        }
    }
    return value;
};

export const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const monthOf31Days = new Set([1, 3, 5, 7, 8, 10, 12]);
export const getDaysOfMonth = (year: number, month: number): number => {
    if (month === 2) {
        if (isLeapYear(year)) {
            return 29;
        }
        return 28;
    }
    if (monthOf31Days.has(month)) {
        return 31;
    }
    return 30;
};

export interface DateDiff {
    day: number;
    month: number;
    year: number;
}

export const getDateDiff = (date0: Date, date1: Date, sort = false): DateDiff => {
    if (date0.getTime() > date1.getTime()) {
        if (sort) {
            const date = date0;
            date0 = date1;
            date1 = date;
        }
        return { day: 0, month: 0, year: 0 } as DateDiff;
    }

    const startDate = new Date(date0);
    const endDate = new Date(date1);
    const dateDiff: DateDiff = { day: 0, month: 0, year: 0 };
    // 1. compute day
    if (startDate.getDate() <= endDate.getDate()) {
        dateDiff.day = endDate.getDate() - startDate.getDate();
        startDate.setDate(endDate.getDate());
    } else {
        dateDiff.day = getDaysOfMonth(startDate.getFullYear(), startDate.getMonth()) - startDate.getDate() + endDate.getDate();
        startDate.setDate(endDate.getDate());
        startDate.setMonth(startDate.getMonth() + 1);
    }

    // 2. compute month
    if (startDate.getMonth() <= endDate.getMonth()) {
        dateDiff.month = endDate.getMonth() - startDate.getMonth();
        startDate.setMonth(endDate.getMonth());
    } else {
        dateDiff.month = 12 - startDate.getMonth() + endDate.getMonth();
        startDate.setMonth(endDate.getMonth());
        startDate.setFullYear(startDate.getFullYear() + 1);
    }
    // 3. compute year
    dateDiff.year = endDate.getFullYear() - startDate.getFullYear();
    return dateDiff;
};

export const appendTag = (originTags: string, newTag: string): string => {
    if (originTags === '') {
        return newTag;
    }
    return originTags + ' ' + newTag;
};

export const getFormatDateDiff = (date0: Date, date1: Date): string => {
    const dateDiff = getDateDiff(date0, date1);
    if (dateDiff.year === 0 && dateDiff.month === 0 && dateDiff.day === 0) {
        return '0 day';
    }
    let ret = '';
    if (dateDiff.year > 1) {
        ret = appendTag(ret, dateDiff.year + ' years');
    } else if (dateDiff.year === 1) {
        ret = appendTag(ret, '1 year');
    }
    if (dateDiff.month > 1) {
        ret = appendTag(ret, dateDiff.month + ' months');
    } else if (dateDiff.month === 1) {
        ret = appendTag(ret, '1 month');
    }
    if (dateDiff.day > 1) {
        ret = appendTag(ret, dateDiff.day + ' days');
    } else if (dateDiff.year === 1) {
        ret = appendTag(ret, '1 day');
    }
    if (ret === '') {
        return '< 1 day';
    }

    return ret;
};

export const getHiddenAddress = (address: string): string => {
    if (!address) {
        return address;
    }
    const len = address.length;
    if (len !== 42) {
        return address;
    }
    return address.slice(0, 5) + '..' + address.slice(len - 2, len);
};

export const viewTrans = (chainId: ChainId, tx?: string) => window.open(`${getScanUrl(chainId)}tx/${tx?.toLowerCase()}`, '_blank');

export const viewAddress = (chainId: ChainId, addr?: string) =>
    window.open(`${getScanUrl(chainId)}address/${addr?.toLowerCase()}`, '_blank');

export function parseJsonRpcError(e: any) {
    let error = e.toString();
    if (error.indexOf('Internal JSON-RPC error.') > -1) {
        error = error.replace('\n', '').replace('Error: ', '').replace('Internal JSON-RPC error.', '');
        error = JSON.parse(error);
    }
    return error.message || error;
}

export function calRatio(current?: number, pre?: number): number | undefined {
    if (!current || !pre) return undefined;
    return pre === 0 ? undefined : (current - pre) / pre;
}
