import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'iZiSwap',
            img: 'Trade.png',
            address: '/trade/swap',
            marker: 'new',
            children: [
                {
                    name: 'Swap',
                    address: '/trade/swap',
                    icon: 'Swap.svg',
                },
                {
                    name: 'Limit Order',
                    address: '/trade/limit',
                    icon: 'LimitOrder.svg',
                },
                {
                    name: 'Pro',
                    address: '/trade/pro',
                    icon: 'Pro.svg',
                },
                {
                    name: 'Pools',
                    address: '/trade/pools',
                    icon: 'Pools.svg',
                },

                {
                    name: 'Liquidity',
                    address: '/trade/liquidity',
                    icon: 'Liquidity.svg',
                },
                {
                    name: 'Campaign',
                    address: '/trade/campaign',
                    icon: 'Campaign.svg',
                },
                {
                    name: 'Analytics',
                    address: links.analyticLink,
                    icon: 'Analytics.svg',
                },
            ],
        },
        {
            name: 'LiquidBox',
            img: 'Farm.svg',
            address: '/farm',
            children: [
                {
                    name: 'iZiSwap Farm',
                    address: '/farm/iZi/dynamic',
                    icon: 'iZiSwapFarm.svg',
                },
                {
                    name: 'Uniswap V3 Farm',
                    address: '/farm/uni/dynamic',
                    icon: 'UniswapFarm.svg',
                },
            ],
        },
        {
            name: 'veiZi',
            img: 'VeiZi.svg',
            address: '/veiZi/locker',
            children: [
                {
                    name: 'Locker',
                    address: '/veiZi/locker',
                    icon: 'Locker.svg',
                },
            ],
        },
        {
            name: 'Portal',
            img: 'Portal.svg',
            address: '/portal',
            children: [
                {
                    name: 'Portal',
                    address: '/portal',
                    icon: 'Portal.svg',
                },
            ],
        },

        {
            name: 'more-info',
            img: 'More.svg',
            address: undefined,
            children: [
                {
                    name: 'Github',
                    address: links.github,
                    icon: 'Github.svg',
                },
                {
                    name: 'Docs',
                    address: links.docs,
                    icon: 'Docs.svg',
                },
                {
                    name: 'Developer docs',
                    address: links.developerDocs,
                    icon: 'DeveloperDocs.svg',
                },
            ],
        },
    ],
} as { pages: PageConfig[] };
