import { Flex, Text, Image, useColorMode, useBreakpointValue, Divider } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    i_h2_title,
    i_h4,
    i_h5,
    i_text_16_bold,
    i_text_cardTitle,
    i_text_copy,
    i_text_copy_bold,
    i_text_liquid,
    i_text_piece3,
} from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { getColorThemeSelector } from '../../../../utils/funcs';
import './HomePiece5.css';
import '../../Home.css';
import { useTranslation } from 'react-i18next';

const HomePiece5: React.FC = () => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isPC = useBreakpointValue({ base: false, xl: true });

    const [card1Index, setCard1Index] = useState(1);
    const [card2Index, setCard2Index] = useState(0);
    const history = useHistory();
    const [cardFlow, setCardFlow] = useState(false);

    return (
        <Flex className={cardFlow ? 'cardFlow' : 'cardLeave'} w="100%" justifyContent="center" mt="35px">
            <Card
                w={{ base: '355px', md: '670px', xl: '967px' }}
                h={{ base: '742px', md: '640px', xl: '574px' }}
                onMouseOver={() => {
                    setCardFlow(true);
                }}
                onMouseLeave={() => {
                    setCardFlow(false);
                }}
            >
                <Flex
                    h={{ base: '180px', md: '153px', xl: '98px' }}
                    pl={{ base: '30px', md: '41px' }}
                    pr="20px"
                    direction={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'start', md: 'center' }}
                    borderRadius="6px 6px 0px 0px"
                    bg={colorTheme('linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 83.85%, #FFFFFF 100%)', '#292343')}
                    boxShadow={{
                        base: '',
                        xl: colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)'),
                    }}
                    zIndex="5"
                    pt={{ base: '19px', md: '0px' }}
                >
                    <Flex>
                        <Image boxSize="28px" src={process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon2.svg'}></Image>
                        <Text className={i_h2_title} pl="15px">
                            {t('LiquidBox')}
                        </Text>
                    </Flex>
                    <Flex
                        w={{ base: '100%', md: '300px', xl: 'unset' }}
                        ml={{ base: 'unset', md: 'auto' }}
                        mt={{ base: '29px', md: '0px' }}
                    >
                        <Flex w={{ base: '33%', md: '33%', xl: '33%' }} direction="column" mx={{ base: '0px', xl: '20px' }}>
                            <Text className={i_text_piece3} color="#0166FF">
                                {t('Max APR')}
                            </Text>
                            <Text className={i_text_liquid} mt="5px">
                                283.75
                                <Text as="span" color="tertiary.400">
                                    %
                                </Text>
                            </Text>
                        </Flex>
                        <Flex w={{ base: '33%', md: '' }} direction="column" mx={{ base: '0px', xl: '20px' }}>
                            <Text className={i_text_piece3} color="#0166FF">
                                {t('APR Boost')}
                            </Text>
                            <Text className={i_text_liquid} mt="5px">
                                2.5
                                <Text as="span" color="tertiary.400">
                                    x
                                </Text>
                            </Text>
                        </Flex>
                        <Flex w={{ base: '33%', md: '' }} direction="column" ml={{ base: '', xl: '20px' }} mr={{ base: '', xl: '50px' }}>
                            <Text className={i_text_piece3} color="#0166FF">
                                {t('Total Liquidity')}
                            </Text>
                            <Text className={i_text_liquid} mt="5px">
                                $35.27M
                            </Text>
                        </Flex>
                    </Flex>
                    {!isMobile && (
                        <CustomButton
                            className={i_text_copy}
                            w={{ base: '151px', xl: '205px' }}
                            h={{ base: '42px', xl: '51px' }}
                            variant="primary"
                            text={t('Farm Now')}
                            fontWeight="700"
                            onClick={() => {
                                history.push('/farm');
                            }}
                        ></CustomButton>
                    )}
                </Flex>
                {!isPC && (
                    <Flex direction="column">
                        <Flex w="100%" className={i_h5} textAlign="center" bgColor={colorTheme('', '#292343')} cursor="pointer">
                            <Flex w="50%" direction="column">
                                <Text
                                    color={card1Index ? 'primary.500' : 'tertiary.500'}
                                    onClick={() => {
                                        setCard1Index(1);
                                        setCard2Index(0);
                                    }}
                                >
                                    {t('For Liquidity Providers')}
                                </Text>
                                <Divider
                                    mt="9px"
                                    borderWidth={card1Index ? '3px' : '1px'}
                                    borderColor={card1Index ? '#0166FF' : '#9B8FAE'}
                                    borderRadius="28px"
                                ></Divider>
                            </Flex>
                            <Flex w="50%" direction="column">
                                <Text
                                    color={card2Index ? 'primary.500' : 'tertiary.500'}
                                    onClick={() => {
                                        setCard1Index(0);
                                        setCard2Index(1);
                                    }}
                                >
                                    {t('For Protocols')}
                                </Text>
                                <Divider
                                    w="98%"
                                    mt="9px"
                                    borderWidth={card2Index ? '3px' : '1px'}
                                    borderColor={card2Index ? '#0166FF' : '#9B8FAE'}
                                    borderRadius="28px"
                                ></Divider>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
                <Flex flex="1" direction="column">
                    <Flex w="100%">
                        <Flex className={card1Index ? 'slide1' : ''}>
                            <Card
                                position="absolute"
                                w={{
                                    base: '354px',
                                    md: '670px',
                                    xl: '757px',
                                }}
                                h="474px"
                                // left="180px"
                                zIndex={card1Index}
                                cursor="pointer"
                                onClick={() => {
                                    setCard1Index(1);
                                    setCard2Index(0);
                                }}
                                bgColor={colorTheme('#ffffff', '#211834')}
                                // transform="translate(30%)"
                            >
                                {isPC && (
                                    <Text className={i_h4} fontWeight="800" ml="42px" mt="27px">
                                        <Text as="span" color="#9B8FAE">
                                            {t('For')}
                                        </Text>{' '}
                                        {t('Liquidity Providers')}
                                    </Text>
                                )}
                                <Flex opacity={card1Index > card2Index ? '1' : '0.2'} direction="column">
                                    <Flex w="100%" overflow="auto">
                                        <Image
                                            w="670px"
                                            maxW="unset"
                                            src={colorTheme(
                                                process.env.PUBLIC_URL + '/assets/home/homePiece5/tab1.svg',
                                                process.env.PUBLIC_URL + '/assets/home/homePiece5/darkTab1.svg'
                                            )}
                                        ></Image>
                                    </Flex>
                                    {isMobile && (
                                        <Flex ml="24px" mt="5px">
                                            <Image src={process.env.PUBLIC_URL + '/assets/home/homePiece5/swipe.svg'}></Image>
                                            <Text ml="7px" className={i_text_copy_bold} color={colorTheme('primary.500', 'primary.400')}>
                                                {t('Swipe Right')}
                                            </Text>
                                        </Flex>
                                    )}
                                    <Flex
                                        direction={{
                                            base: 'column',
                                            md: 'row',
                                        }}
                                        justifyContent="center"
                                        mt={{
                                            base: '12px',
                                            md: '60px',
                                            xl: '12px',
                                        }}
                                        ml="24px"
                                    >
                                        <Flex w="272px" direction="column" mr={{ base: '6px', xl: '62px' }}>
                                            <Text className={i_text_16_bold}>{t('Safe to Farm')}</Text>
                                            <Text className={i_text_cardTitle} color="#6A5E86" mt="10px">
                                                {t(
                                                    'Keep your positions safe in Uniswap and iZiSwap, fully decentralized and non-custodial.'
                                                )}
                                            </Text>
                                        </Flex>
                                        <Flex w="272px" direction="column" mt={{ base: '28px', md: '0px' }}>
                                            <Text className={i_text_16_bold}>{t('High APR to Enjoy')}</Text>
                                            <Text className={i_text_cardTitle} color="#6A5E86" mt="10px">
                                                {t('Stake to earn LP fees and liquidity mining incentives at the same time!')}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Card>
                        </Flex>
                        <Flex w={{ base: '354px', md: '670px', xl: '757px' }} h="474px" ml="auto">
                            <Flex className={card2Index ? 'slide2' : ''}>
                                <Card
                                    position="absolute"
                                    w={{
                                        base: '354px',
                                        md: '670px',
                                        xl: '757px',
                                    }}
                                    h="474px"
                                    // right="180px"
                                    zIndex={card2Index}
                                    cursor="pointer"
                                    onClick={() => {
                                        setCard1Index(0);
                                        setCard2Index(1);
                                    }}
                                    bgColor={colorTheme('#ffffff', '#211834')}
                                >
                                    {isPC && (
                                        <Text className={i_h4} fontWeight="800" ml="auto" mr="40px" mt="27px" mb="29px">
                                            <Text as="span" color="#9B8FAE">
                                                {t('For')}
                                            </Text>{' '}
                                            {t('Protocols')}
                                        </Text>
                                    )}
                                    <Flex direction="column" opacity={card2Index > card1Index ? '1' : '0.2'}>
                                        <Flex w="100%" overflow="auto">
                                            <Image
                                                w={{
                                                    base: '609px',
                                                    xl: '637px',
                                                }}
                                                h={{
                                                    base: '235px',
                                                    xl: '215px',
                                                }}
                                                maxW="unset"
                                                src={colorTheme(
                                                    process.env.PUBLIC_URL + '/assets/home/homePiece5/tab2.svg',
                                                    process.env.PUBLIC_URL + '/assets/home/homePiece5/darkTab2.svg'
                                                )}
                                            ></Image>
                                        </Flex>
                                        {isMobile && (
                                            <Flex ml="24px" mt="5px">
                                                <Image src={process.env.PUBLIC_URL + '/assets/home/homePiece5/swipe.svg'}></Image>
                                                <Text
                                                    ml="7px"
                                                    className={i_text_copy_bold}
                                                    color={colorTheme('primary.500', 'primary.400')}
                                                >
                                                    {t('Swipe Right')}
                                                </Text>
                                            </Flex>
                                        )}
                                        <Flex
                                            direction={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            justifyContent="center"
                                            mt={{
                                                base: '12px',
                                                md: '60px',
                                                xl: '12px',
                                            }}
                                            ml="24px"
                                        >
                                            <Flex
                                                w="272px"
                                                direction="column"
                                                mr={{
                                                    base: '6px',
                                                    md: '72px',
                                                    xl: '62px',
                                                }}
                                            >
                                                <Text className={i_text_16_bold}>{t('Efficient Liquidity Service')}</Text>
                                                <Text className={i_text_cardTitle} color="#6A5E86" mt="10px">
                                                    {t(
                                                        'LiquidBox are providing liquidity service to help projects improve on-chain liquidity and provide incentives in an efficient and lasting way'
                                                    )}
                                                </Text>
                                            </Flex>
                                            <Flex w="272px" direction="column" mt={{ base: '28px', md: '0px' }}>
                                                <Text className={i_text_16_bold}>{t('LaaS on Multi-chains')}</Text>
                                                <Text className={i_text_cardTitle} color="#6A5E86" mt="10px">
                                                    {t(
                                                        'LiquidBox is now available on Ethereum, Polygon, Arbitrum. More EVM compatible chains will be supported in near future'
                                                    )}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                {isMobile && (
                    <Flex w="100%" justifyContent="center" mt="auto">
                        <CustomButton
                            className={i_text_copy}
                            fontSize="12px !important"
                            w="299px"
                            h="51px"
                            variant="primary"
                            text={t('Farm Now')}
                            fontWeight="700"
                            mb="39px"
                            onClick={() => {
                                history.push('/farm');
                            }}
                        ></CustomButton>
                    </Flex>
                )}
            </Card>
        </Flex>
    );
};

export default HomePiece5;
