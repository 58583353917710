import { SimpleGrid, Stack } from '@chakra-ui/react';
import { PORTAL } from '../../config/portal/config';
import { PartnerCard } from './components/PartnerCard';

export const PortalBlock: React.FC = () => {
    return (
        <Stack w="100%">
            <SimpleGrid columns={{ base: 2, lg: 3, xxl: 4 }} spacing="18px" my="25px">
                {PORTAL &&
                    PORTAL.map((item: any, index: number) => {
                        return (
                            <PartnerCard
                                key={index}
                                titleImgSrc={item.titleImgSrc}
                                title={item.title}
                                content={item.content}
                                link={item.link}
                            ></PartnerCard>
                        );
                    })}
            </SimpleGrid>
        </Stack>
    );
};
