import React from 'react';
import { Box, HStack, Stack, useColorMode, Text } from '@chakra-ui/react';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import PageLayout, { AdaptationMode } from '../../../../components/layout/PageLayout';
import NetworkSelect from '../../../../components/Select/NetworkSelect/NetworkSelect';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { PoolList } from './components/PoolList';
import { Heading } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { i_h3 } from '../../../../style';
import Controls from '../../components/Controls';
import { useSelector } from 'react-redux';
import { RootState, RootDispatch } from '../../../../state/store';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import IZiLinkButton from '../../../../components/Buttons/iZiLinkButton/iZiLinkButton';
import ChainNotValidBlockWrapper from '../../../components/ChainNotValidBlock';
import { DashboardLinkButton } from '../../components/DashboardLinkButton';
import TypeSelect from '../../components/TypeSelect';
import WalletButton from '../../../../components/Buttons/WalletButton/WalletButton';
import HomeJumpButton from '../../../../components/Buttons/HomeJumpButton/HomeJumpButton';
import { useTranslation } from 'react-i18next';

type FarmDynamicRangeiZiProps = {
    type?: AdaptationMode;
};
export const FarmDynamicRangeiZi: React.FC<FarmDynamicRangeiZiProps> = (props) => {
    const { type } = props;
    useDocumentTitle('Farm | izumi.finance');
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;
    const { farmDynamicRangeiZi: farm } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    return type === 'mobile' ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%">
                            <Controls farm={farm} dispatch={dispatch.farmDynamicRangeiZi} />
                            <TypeSelect dex="iZi" type="mobile" />
                            <Box h="10px" />
                            <PoolList />
                        </Stack>
                    }
                    app="farmiZi"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                {t('Dynamic Range')}
                            </Heading>
                            <Box h="60%" w="3px" bg={colorTheme('tertiary.200', 'tertiary.200')} />
                            <Text
                                className={i_h3}
                                fontSize={oneLineMode ? '24px' : '20px'}
                                color={colorTheme('secondary.600', 'secondary.300')}
                            >
                                {t('Earn More Fees')}
                            </Text>
                        </HStack>
                    }
                    subtitle={t('Provide liquidity with dynamic price range to earn more fees.')}
                    element={
                        <HStack spacing="10px">
                            <DashboardLinkButton />
                            <IZiLinkButton />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%">
                            <Controls farm={farm} dispatch={dispatch.farmDynamicRangeiZi} />
                            <TypeSelect dex="iZi" />
                            <Box h="10px" />
                            <PoolList />
                        </Stack>
                    }
                    app="farmiZi"
                />
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};

export default FarmDynamicRangeiZi;
