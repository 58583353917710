import { BoxProps, useColorMode, HStack, VStack, Text, Image, Box, Stack } from '@chakra-ui/react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { TokenSymbol } from '../../../../../types/mod';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_text_d } from '../../../../../style';
import tokenContracts from '../../../../../config/tokens';
import { BoostAmountInput } from '../../../components/BoostAmountInput';
import { ModalHead } from '../../../components/ModalHead';
import { useSelector } from 'react-redux';
import { RootState, RootDispatch } from '../../../../../state/store';
import { useEffect, useState, useMemo } from 'react';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { amount2Decimal, decimal2Amount, formatNumber } from '../../../../../utils/tokenMath';
import { isNumeric } from '../../../../../utils/valid';
import BigNumber from 'bignumber.js';
import { useMiningFixRangeContract } from '../../../../../hooks/useContracts';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import { niZiForDesiredBoost } from '../../../../../state/models/farm/UniswapV3/oneSide/funcs';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { ModalAprBlock } from '../../../components/ModalAprBlock';
import { buildSendingParams } from '../../../../../utils/contractHelpers';
import { useIZiToken } from '../../../../../hooks/useiZiToken';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../../config/chains';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';

type BoostModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    entry: any;
    index: number;
} & BoxProps;

export const BoostModal: React.FC<BoostModalProps> = (props) => {
    const { isOpen, onClose, entry, index, onSuccess, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { token, account: accountModel } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { account, web3, chainId } = useWeb3WithDefault();
    const [niZiDecimal, setNiZiDecimal] = useState(0);
    const toast = useCustomToast();
    const { gasPrice } = useGasPrice();
    const { iZiToken } = useIZiToken();

    const niZi = useMemo(() => {
        return decimal2Amount(new BigNumber(niZiDecimal), iZiToken) ?? new BigNumber(0);
    }, [niZiDecimal, chainId]);

    const { contract } = useMiningFixRangeContract(entry.meta.miningContract, entry.meta.contractVersion);

    const position = useMemo(() => {
        return [...(entry.positionList ?? []), ...(entry.stakedPositionList ?? [])][index];
    }, [entry, index]);

    const baseAPY = useMemo(() => {
        return Array.isArray(entry.data.apr) ? entry.data.apr[0] : 0;
    }, [entry]);

    const [boostAPY, setBoostAPY] = useState(baseAPY);
    const [boostRate, setBoostRate] = useState(1);

    useEffect(() => {
        dispatch.account.fetchTokenBalanceIfMissing({
            account: account as unknown as any,
            web3,
            token: iZiToken,
            chainId,
        });
        dispatch.token.fetchTokenPriceIfMissing(iZiToken);
    }, [account, web3, chainId, dispatch, iZiToken]);

    useEffect(() => {
        const f = async () => {
            const totalNiZi = entry.data.totalNiZi;
            const totalVliquidity = entry.data.totalVLiquidity;
            const positionVLiquidity = position.positionVLiquidity;
            let positionNiZi = position.niZi;
            const newAddNiZi = parseFloat(niZi.toString());
            positionNiZi += newAddNiZi;
            const vliquidityShare = positionVLiquidity / totalVliquidity;
            let iZiShare = 1;
            if (newAddNiZi + totalNiZi > 0) {
                iZiShare = positionNiZi / (newAddNiZi + totalNiZi);
            }
            setBoostAPY(Math.min(baseAPY * (1 + (1.5 * iZiShare) / vliquidityShare), baseAPY * 2.5));
            setBoostRate(0);
        };
        f();
    }, [contract, setBoostAPY, niZi, baseAPY, entry, position]);

    useEffect(() => {
        const f = async () => {
            if (boostRate === 0) {
                return;
            }
            const totalNiZi = entry.data.totalNiZi;
            const totalVliquidity = entry.data.totalVLiquidity;
            const positionVLiquidity = position.positionVLiquidity;
            const positionNiZi = position.niZi;

            const iZiAmount = niZiForDesiredBoost(positionVLiquidity, totalVliquidity, totalNiZi - positionNiZi, boostRate);
            let iZiNeeded = iZiAmount - positionNiZi;
            console.log('----------iziNeeded: ', iZiNeeded);
            iZiNeeded = iZiNeeded < 0 ? 0 : iZiNeeded;
            const neededNiZiDecimal = amount2Decimal(new BigNumber(iZiNeeded), iZiToken) ?? 0;
            setNiZiDecimal(neededNiZiDecimal);
        };
        f();
    }, [contract, setBoostAPY, boostRate, baseAPY, entry, position]);

    const iZiEntity = useTokenEntity(iZiToken, entry.meta.miningContract);

    const tokenIconBlock = (token: any, amount: any) => (
        <VStack w="100%" justifyContent="space-between" pt="20px">
            <HStack>
                <Image borderRadius="9px" w="30px" h="30px" src={process.env.PUBLIC_URL + tokenContracts[token as TokenSymbol].icon} />
                <Text className={i_text_copy_bold} fontSize="32px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                    {token}
                </Text>
            </HStack>
            <VStack alignItems="center" spacing="10px" mt="30px !important">
                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                    {t('iZi Staked')}:
                </Text>

                <Text className={i_text_copy} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                    {amount}
                </Text>
            </VStack>
        </VStack>
    );

    const ApproveButton = (
        <CustomButton
            text={t('Approve') + ' ' + TokenSymbol.IZI}
            variant="purple"
            mb="2px"
            w={{ base: '100%', sm: '272px' }}
            h="50px"
            ml={{ base: '0px', sm: '30px' }}
            onClick={() => {
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                iZiEntity
                    .handleApprove()
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Approving iZi for the mining contract...', undefined, toastLink);
                    })
                    .then(() => {
                        toast('success', 'Approve iZi successfully.', undefined, toastLink);
                        iZiEntity.handleApproveSuccess();
                    })
                    .catch((e: any) => {
                        console.info('error   :', e.message);
                    });
            }}
        />
    );

    const BoostButton = (
        <CustomButton
            variant="purple"
            text={t('Boost')}
            mb="2px"
            w={{ base: '100%', sm: '272px' }}
            h="50px"
            ml={{ base: '0px', sm: '30px' }}
            onClick={() => {
                contract?.methods
                    .depositIZI(position.nftId, niZi.toFixed(0))
                    .estimateGas({ from: account })
                    .then((gas: number) => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                        contract?.methods
                            .depositIZI(position.nftId, niZi.toFixed(0))
                            .send(
                                buildSendingParams(
                                    chainId,
                                    {
                                        from: account,
                                        gas: gasLimit,
                                        maxFeePerGas: gasPrice,
                                    },
                                    gasPrice
                                )
                            )
                            .on('transactionHash', (hash: string) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(hash, chain);
                                }
                                toast('info', 'Boosting APR with iZI ...', undefined, toastLink);
                            })
                            .then((r: any) => {
                                console.log(r);
                                toast('success', 'Boost successfully.', undefined, toastLink);
                                onSuccess?.();
                                onClose();
                            });
                    });
            }}
        />
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '73%', sm: '744px' }} h="750px" title={t('Boost APR')} {...rest}>
            <ModalHead entry={entry} index={index} dispatch={dispatch.farmFixRange} />

            <Card variant="deep" w="100%" h={{ base: 'unset', sm: '335px' }} mt="20px">
                <VStack w="100%" h={{ base: 'unset', sm: '180px' }} p={{ base: '10px', sm: '20px 30px 20px 30px' }}>
                    <Stack w="100%" direction={{ base: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
                        <Box w="40%">{tokenIconBlock(TokenSymbol.IZI, formatNumber(position.niZiDecimal, 2, 4))}</Box>

                        <VStack w={{ base: '100%', sm: '60%' }} spacing="10px" alignItems="flex-start">
                            <Stack
                                w="100%"
                                direction={{ base: 'column', sm: 'row' }}
                                alignItems="center"
                                justifyContent="space-between"
                                px="10px"
                            >
                                <Text className={i_text_copy_bold}>{t('Amount')}</Text>
                                <HStack>
                                    <Text className={i_text_d} color="tertiary.400" fontSize="12px !important">
                                        {t('Balance')}:
                                    </Text>
                                    <Text className={i_text_d} fontSize="12px !important" color="tertiary.400">
                                        {accountModel.tokenBalance[TokenSymbol.IZI]
                                            ? formatNumber(accountModel.tokenBalance[TokenSymbol.IZI]?.[0], 2, 4)
                                            : '0.00'}
                                    </Text>
                                </HStack>
                            </Stack>
                            <Card w="100%" h="80px" px="10px">
                                <BoostAmountInput
                                    h="60px"
                                    errorInfo=""
                                    w="50%"
                                    inputValue={niZiDecimal}
                                    setBoostRate={setBoostRate}
                                    maxRequire={100}
                                    price={getSafeTokenPrice(token, TokenSymbol.IZI)}
                                    token={TokenSymbol.IZI}
                                    handleSetValue={(v) => {
                                        isNumeric(v) && setNiZiDecimal(v);
                                    }}
                                />
                            </Card>
                        </VStack>
                    </Stack>
                </VStack>

                <ModalAprBlock beforeBoostAPR={position.currentAPR} afterBoostAPR={boostAPY} baseAPR={baseAPY} />
            </Card>

            <VStack alignItems="center" mt="20px">
                {iZiEntity.isApproved() ? BoostButton : ApproveButton}
            </VStack>
        </Modal>
    );
};
