import { useMemo } from 'react';
import { getFactoryContract } from '../../utils/contractHelpers';


export const useFactoryContract = (chainId?:any, web3?: any) =>{
    const contract = useMemo(()=>{
        return getFactoryContract(chainId, web3);
    }, [chainId, web3]);

    return contract;
};
