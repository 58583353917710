import { Flex, useBreakpointValue } from '@chakra-ui/react';
import CenterCard from './CenterCard';
import './HomePiece3.css';

const HomePiece3: React.FC<unknown> = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isPC = useBreakpointValue({ base: false, xl: true });
    const breakLine = isPC ? 4 : isMobile ? 4 : 2;
    const tabs = [
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/Trade.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon1.svg',
            title: 'iZiSwap',
            content:
                'A next-generation DEX on Multi-Chains to maximize capital efficiency with the innovative Discretized-Liquidity-AMM model',
            buttonText: 'Trade',
            route: '/trade/swap',
        },
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/Farm.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon2.svg',
            title: 'LiquidBox',
            content: 'A Non-Custodial Solution for Programmable Liquidity Mining on Uniswap V3 and iZiSwap',
            buttonText: 'Farm',
            route: '/farm',
        },
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/Learn.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon1.svg',
            title: 'iUSD',
            content: 'The first Liquidity-Mining-Based bond with no Impermanent Loss for LP Farming, 100% collateralized by iZUMi Finance',
            buttonText: 'Learn More',
            route: 'https://docs.izumi.finance/product/izumi-bond-usd-iusd',
        },
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/VeiZi.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon4.svg',
            title: 'DAO veNFT',
            content: 'The governance rights of iZUMi Finance, which includes voting, boosting and returning staking rewards',
            buttonText: 'veiZi Locker',
            route: '/veiZi/locker',
        },
    ];

    return (
        <Flex className="imageRender" w="100%" alignItems="center" justifyContent="center" direction={{ base: 'column', xl: 'row' }}>
            <Flex direction={isMobile ? 'column' : 'row'}>
                {tabs.slice(0, breakLine).map((item, index) => {
                    return <CenterCard key={index} item={item} index={index}></CenterCard>;
                })}
            </Flex>
            <Flex>
                {tabs.slice(breakLine, tabs.length).map((item, index) => {
                    return <CenterCard key={index} item={item} index={index}></CenterCard>;
                })}
            </Flex>
        </Flex>
    );
};

export default HomePiece3;
