import { ChainId, TokenSymbol } from '../types/mod';
import chains from './chains';
import { tokenSymbol2token } from './tokens';

export const DISABLE_CONSOLE = false;

export const TOKEN_CONFIG = {
    AUTO_REFRESH_TOKEN_PRICE_INTERVAL: 60 * 1000,
    INITIAL_FETCH_TOKEN_LIST: [
        TokenSymbol.USDC,
        TokenSymbol.ETH,
        TokenSymbol.WETH,
        TokenSymbol.USDT,
        TokenSymbol.IZI,
        TokenSymbol.BUSD,
        TokenSymbol.WBNB,
        TokenSymbol.IUSD,
        TokenSymbol.WBNB,
        TokenSymbol.BNB,
    ],
};

export const DEFAULT_CHAIN_ID = ChainId.BSC;
export const DEFAULT_CHAIN_ID_TEST = ChainId.BSCTestnet;

export const FARM_CONFIG = {
    AUTO_REFRESH_FARM_DATA_INTERVAL: 6 * 1000,
    REWARD_DISPLAY: 'day', // day or block/second
    APR_FEE_GAINS: {
        [ChainId.EthereumMainnet]: 2,
        [ChainId.Matic]: 6,
        [ChainId.Optimism]: 0,
        [ChainId.BSC]: 0,
        [ChainId.Gatechain]: 0,
        [ChainId.Arbitrum]: 3,
        [ChainId.Rinkeby]: 0,
        [ChainId.Aurora]: 0,
        [ChainId.ETC]: 0,
    } as Record<ChainId, number>,
    ALL_FACTOR: 0.99999,
};

export const UNISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
    AUTO_REFRESH_POOL_STATE_INTERVAL: 15 * 1000,
};

export const IZUMI_SWAP_CONFIG = {
    AUTO_REFRESH_LIQUIDITY_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_SWAP_CALC_INTERVAL: 15 * 1000,

    ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT: 2,

    SWAP_DEFAULT_SLIPPAGE_PERCENT: 0.5,
    TAP_SWAP_DESIRED_MIN_AMOUNT_FACTOR: 0.995,
    SWAP_DEFAULT_MAXIMUM_DELAY: 10,
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.985,

    DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR: 0.85,

    SWAP_PRICE_FEE_SWITCH: false, // if true, displayed swap price (current price) will count in fee cost
};

export const ACCOUNT_CONFIG = {
    AUTO_REFRESH_TOKEN_BALANCE_INTERVAL: 15 * 1000,
};

export const CHAIN_CONFIG = {
    AUTO_REFRESH_GAS_PRICE_INTERVAL: 10 * 1000,
    AUTO_REFRESH_BLOCK_NUMBER_INTERVAL: 5 * 1000,
};

export const PRO_CONFIG = {
    KLINE_AUTO_REFRESH_INTERVAL: 15 * 1000,
    ORDERBOOK_AUTO_REFRESH_PRICE_INTERVAL: 3 * 1000,
    POOL_STATE_REFRESH_INTERVAL: 15 * 1000,
};

export const PRO_DEFAULT_MARKET = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const markets = {
        //mainnet
        [ChainId.BSC]: {
            tokenX: tokenSymbol2token(TokenSymbol.WBNB, ChainId.BSC),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSC),
            pool: '0xf964529721ecd0c9386d922a37cbbd2b67ea6e93',
            fee: 2000,
            market: 'WBNB_USDT_2000',
        },
        [ChainId.Aurora]: {
            tokenX: tokenSymbol2token(TokenSymbol.AURORA, ChainId.Aurora),
            tokenY: tokenSymbol2token(TokenSymbol.USDC, ChainId.Aurora),
            pool: '0x8fa7df05e9422115088515ec2af03ab8883bdabb',
            fee: 2000,
            market: 'AURORA_USDC_2000',
        },
        [ChainId.Arbitrum]: {
            tokenX: tokenSymbol2token(TokenSymbol.WETH, ChainId.Arbitrum),
            tokenY: tokenSymbol2token(TokenSymbol.USDC, ChainId.Arbitrum),
            pool: '0x6336e3f52d196b4f63ee512455237c934b3355eb',
            fee: 2000,
            market: 'WETH_USDC_2000',
        },
        [ChainId.ZkSyncEra]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDC, ChainId.ZkSyncEra),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.ZkSyncEra),
            pool: '0x6ac81d4c43C86c8DbD4842c1eb0fd1a1c2C16b97',
            fee: 2000,
            market: 'USDC_WETH_2000',
        },
        [ChainId.Mantle]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDT, ChainId.Mantle),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.Mantle),
            pool: '0x1dBD28aAF4bEF820e4Fc92526C06D8e0639347DE',
            fee: 3000,
            market: 'USDT_WETH_3000',
        },
        [ChainId.Matic]: {
            tokenX: tokenSymbol2token(TokenSymbol.WMATIC, ChainId.Matic),
            tokenY: tokenSymbol2token(TokenSymbol.USDC, ChainId.Matic),
            pool: '0x495e62d09aee0b38f7ed4305aa6d31ecbe7ec8f5',
            fee: 2000,
            market: 'WMATIC_USDC_2000',
        },
        [ChainId.Meter]: {
            tokenX: tokenSymbol2token(TokenSymbol.WMTR, ChainId.Meter),
            tokenY: tokenSymbol2token(TokenSymbol.MTRG, ChainId.Meter),
            pool: '0xb2cacb0c9ff481e1f5fa4bc220fc31346e768cf5',
            fee: 2000,
            market: 'WMTR_MTRG_2000',
        },
        [ChainId.ConfluxESpace]: {
            tokenX: tokenSymbol2token(TokenSymbol.WCFX, ChainId.ConfluxESpace),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.ConfluxESpace),
            pool: '0x59c49d02dbf90b525994b773fe5069d76d712e90',
            fee: 2000,
            market: 'WCFX_USDT_2000',
        },
        [ChainId.Icplaza]: {
            tokenX: tokenSymbol2token(TokenSymbol.WICT, ChainId.Icplaza),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.Icplaza),
            pool: '0x13dd42ded12f9f9531e80361b50c64c3c2362a85',
            fee: 2000,
            market: 'WICT_USDT_2000',
        },
        // testnet
        [ChainId.BSCTestnet]: {
            tokenX: tokenSymbol2token(TokenSymbol.WBNB, ChainId.BSCTestnet),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSCTestnet),
            pool: '0x16baebb51e257e9b9b3fe692b7122e80ed26df3b',
            fee: 2000,
            market: 'WBNB_USDT_2000',
        },
        [ChainId.ScrollTestL2]: {
            tokenX: tokenSymbol2token(TokenSymbol.WETH, ChainId.ScrollTestL2),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.ScrollTestL2),
            pool: '0xa5612a12ff3b197e3a82bd18434c7091e62d732a',
            fee: 2000,
            market: 'WETH_USDT_2000',
        },
        [ChainId.LineaTest]: {
            tokenX: tokenSymbol2token(TokenSymbol.IZI, ChainId.LineaTest),
            tokenY: tokenSymbol2token(TokenSymbol.USDC, ChainId.LineaTest),
            pool: '0x972C8B328eED91cd2548fEB306fA1dfC636BFafC',
            fee: 2000,
            market: 'iZi_USDC_2000',
        },
        [ChainId.MantleTest]: {
            tokenX: tokenSymbol2token(TokenSymbol.WMNT, ChainId.MantleTest),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.MantleTest),
            pool: '0x2722c280c3a99902f7c9970fb2076e5266d98207',
            fee: 2000,
            market: 'WMNT_USDT_2000',
        },
    } as any;
    memo[chain.id] = markets[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const AUTO_LOGIN = true;

export const A_LONG_FUTURE_TIME = new Date('2026-01-01').getTime() / 1000;

export const FAKE_PRICE = {
    use: false,
    price: {
        USDC: 0.999999,
        iUSD: 0.9999,
        BNB: 255.98,
        USDT: 1,
        iZi: 0.029,
        BUSD: 1,
    },
} as any;

export const HOT_TOKENS = {
    [ChainId.BSC]: ['BNB', 'iZi', 'iUSD'],
    [ChainId.Aurora]: ['iZi', 'iUSD', 'ARC', 'USN'],
    [ChainId.BSCTestnet]: ['iZi', 'BNB'],
} as any;

export const SEARCH_TOKENS_PRO = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        //mainnet
        [ChainId.BSC]: ['USDT', 'BUSD', 'iUSD', 'WBNB'],
        [ChainId.Aurora]: ['USDT', 'USDC'],
        [ChainId.Icplaza]: ['USDT'],
        [ChainId.ConfluxESpace]: ['USDT'],
        [ChainId.Meter]: ['USDT.eth', 'WMTR'],
        [ChainId.ZkSyncEra]: ['USDC', 'WETH'],
        //testnet
        [ChainId.BSCTestnet]: ['USDT', 'BUSD', 'WBNB'],
        [ChainId.ScrollTestL2]: ['USDT'],
        [ChainId.MantleTest]: ['USDT'],
        [ChainId.LineaTest]: ['USDC', 'USDT', 'WETH'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const CUSTOM_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0x76E4EFB2c78b08c3fdE3eDF98c6503dd9Df9C5E8', //ANT
            '0xa0D289c752FE23043f29BD81e30D8345180bD9BB', //WrappedANT,
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_FROM_TOKEN = chains.all.reduce<Record<number, string | string[]>>((memo, chain) => {
    const tokens = {
        //mainnet
        //[]
        //testnet
        [ChainId.LineaTest]: ['USDC', '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_TO_TOKEN = chains.all.reduce<Record<number, string | string[]>>((memo, chain) => {
    const tokens = {
        //mainnet
        [ChainId.BSC]: 'USDT', //iZi
        [ChainId.ConfluxESpace]: 'USDT',
        [ChainId.Arbitrum]: 'USDC',
        [ChainId.Matic]: 'USDC',
        [ChainId.ZkSyncEra]: 'USDC',
        [ChainId.Telos]: 'USDT',
        [ChainId.Meter]: 'MTRG',
        [ChainId.Icplaza]: 'USDT',
        [ChainId.Ontology]: 'WONT',
        [ChainId.Ultron]: 'uUSDT',
        [ChainId.Mantle]: 'WETH',
        //testnet
        [ChainId.MantleTest]: 'USDT',
        [ChainId.ScrollTestL2]: 'USDT',
        [ChainId.LineaTest]: ['USDT', '0x1990BC6dfe2ef605Bfc08f5A23564dB75642Ad73'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const POOLS_APR_RANGE = {
    [ChainId.ZkSyncEra]: [
        {
            pool_address: '0x6ac81d4c43c86c8dbd4842c1eb0fd1a1c2c16b97',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.2%-[-20%,+20%]
        {
            pool_address: '0x21651f0e067f06ec247679efb0bdd77605019c7c',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.04%-[-20%,+20%]
        {
            pool_address: '0xb50b75a3825933d605ecca994d1365de315a8512',
            leftRange: -2,
            rightRange: 2,
        }, // BUSD-USDC-0.04%-[-2%,+2%]
        {
            pool_address: '0x667f19b8db045db612c1ecb371bcfef9ac3a401e',
            leftRange: -2,
            rightRange: 2,
        }, // slUSDT-USDC-0.04%-[-2%,+2%]
    ],
} as any;

export const SHOW_REFRESH_PAGE = ['/trade/swap', '/trade/liquidity'];

export const FEE_CHARGE_PERCENT = 20;

export const LIMIT_ORDER_DISABLE_CHAIN = [ChainId.Arbitrum, ChainId.Matic, ChainId.EthereumMainnet, ChainId.ZkSyncEra];
